<template>
    <v-responsive :aspect-ratio="1030 / 446">
        <v-card outlined class="h-100 rounded-b-0 overflow-hidden" v-bind="{ loading }">
            <v-row no-gutters class="flex-md-nowrap h-100">
                <v-col cols="12" md="auto" order-md="2">
                    <div class="w-md-230px h-100 grey lighten-5">
                        <div class="d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px">
                            <v-btn icon class="transparent" style="position: absolute; top: 0; right: 0" @click="$emit('close')"><v-icon size="20" color="grey darken-4">mdi-close</v-icon></v-btn>
                            <br class="d-none d-lg-block d-xl-none" />
                            <p class="txt txt--sm txt--dark">조이포라이프몰과 함께하는전세계 브랜드를 이곳에서 모두 만나보실 수 있습니다.</p>
                            <div class="mt-20px mt-md-auto">
                                <v-btn v-bind="{ ...btn_primary, ...$attrs }" block to="/shop/brands">브랜드관 바로가기</v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="" order-md="1" style="width: calc()">
                    <div class="d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px">
                        <div class="overflow-hidden">
                            <tit-wrap-subtitle titSize="xs" class="tit-wrap--sm">
                                <template #tit>브랜드관</template>
                                <template #subTit>핫한 브랜드만 모았습니다!</template>
                            </tit-wrap-subtitle>
                            <v-responsive :aspect-ratio="720 / 316">
                                <v-card class="main-brand-slide">
                                    <div class="txt txt--sm d-flex justify-center align-center">
                                        <v-icon class="mr-2">mdi-arrow-left-right</v-icon>
                                        슬라이드 해서 브랜드들을 확인해 보세요
                                    </div>
                                    <swiper class="swiper" v-bind="{ options }">
                                        <template v-for="item in items">
                                            <swiper-slide :key="item._id">
                                                <v-card :to="`/shop/brands/${item?.code}`">
                                                    <div class="main-brand-slide__img" :style="`background-image:url(${item?.label?.url});`" />
                                                </v-card>
                                            </swiper-slide>
                                        </template>
                                        <!-- <div class="swiper-control" slot="pagination">
                                            <div class="swiper-button-prev">
                                                <icon-arrow-rounded />
                                            </div>
                                            <div class="swiper-button-next">
                                                <icon-arrow-rounded direction="right" />
                                            </div>
                                        </div> -->
                                    </swiper>
                                </v-card>
                            </v-responsive>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-responsive>
</template>

<script>
import api from "@/api";
import { btn_primary } from "@/assets/variables";

import IconArrowRounded from "@/components/client/icons/icon-arrow-rounded.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";

const options = {
    allowTouchMove: true,
    centeredSlides: false,
    slidesPerView: 2,
    slidesPerColumn: 2,
    spaceBetween: 10,

    // autoplay: {
    //     delay: 5000,
    //     waitForTransition: true,
    //     disableOnInteraction: false,
    // },
    speed: 500,
    lazy: {
        loadPrevNext: true,
    },
    loop: false,
    // navigation: {
    //     nextEl: ".main-brand-slide .swiper-button-next",
    //     prevEl: ".main-brand-slide .swiper-button-prev",
    // },
    breakpoints: {
        576: {
            slidesPerView: 3,
            slidesPerColumn: 3,
            spaceBetween: 10,
        },
        1400: {
            slidesPerView: 4,
            slidesPerColumn: 4,
            spaceBetween: 10,
        },
        1800: {
            slidesPerView: 4,
            slidesPerColumn: 4,
            spaceBetween: 18,
        },
    },
};

export default {
    components: {
        IconArrowRounded,
        TitWrapSubtitle,
    },
    data: () => ({
        btn_primary,

        options,

        brands: [],

        loading: false,
    }),
    computed: {
        items() {
            return [...this.brands];
        },
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { brands } = await api.v1.shop.brands.gets({
                    params: { showsInMain: true },
                });
                this.brands = brands;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-responsive,
.main-brand-slide,
.swiper-container {
    overflow: visible;
}
.main-brand-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ::v-deep {
        .swiper-container {
            height: 100%;
        }
    }
    .swiper-slide {
        height: auto;
    }
    .swiper-control {
        bottom: auto;
        top: -22px;
        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px !important;
            height: 20px !important;
            color: var(--v-grey-darken-4);
            background-color: transparent;
            &:hover {
                background-color: transparent;
            }
        }
        .swiper-button-prev {
            left: auto !important;
            right: 44px;
        }
        .swiper-button-next {
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 32px;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                display: block;
                width: 1px;
                height: 10px;
                background-color: var(--v-grey-lighten4);
            }
        }
    }
    &__img {
        width: 100%;
        padding-top: calc(64 / 164 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-brand-slide {
        .swiper-control {
            top: -32px;
        }
    }
}
@media (min-width: 1024px) {
    .main-brand-slide {
        .swiper-control {
            top: -44px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>
