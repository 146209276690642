<template>
    <page-section>
        <v-row class="mx-n10px my-n10px mx-md-n20px mx-lg-n30px">
            <v-col cols="12" class="d-lg-none px-10px py-10px px-md-20px px-lg-30px">
                <div class="tit-wrap">
                    <h2 class="tit tit--lg">{{ CODE?.text }}</h2>
                    <p class="txt txt--sm txt--dark line-height-135">고객님들의 생생한 리뷰를 확인해 보세요!</p>
                </div>
            </v-col>
            <v-col cols="12" lg="" order-lg="2" class="px-10px py-10px px-md-20px px-lg-30px" :style="$vuetify.breakpoint.lgAndUp ? 'max-width: calc(100% - 340px);' : ''">
                <main-displays v-bind="{ code, sort }" skip="0" limit="3">
                    <template #default="{ products }">
                        <v-row>
                            <template v-for="(product, i) in products">
                                <main-reviews-best-item v-bind="{ product }" :index="(i + 1).toString().padStart(2, '0')" :review="product?.review__best" :key="i" />
                            </template>
                        </v-row>
                    </template>
                </main-displays>
            </v-col>
            <v-col cols="12" lg="auto" order-lg="1" class="px-10px py-10px px-md-20px px-lg-30px">
                <div class="d-flex flex-column mw-lg-280px h-100">
                    <div class="tit-wrap d-none d-lg-block">
                        <h2 class="tit tit--lg">{{ CODE?.text }}</h2>
                        <p class="txt txt--sm txt--dark line-height-135">고객님들의 생생한 리뷰를 확인해 보세요!</p>
                    </div>
                    <div class="mt-auto">
                        <v-divider />
                        <main-displays v-bind="{ code, sort }" skip="3" limit="3">
                            <template #default="{ products }">
                                <template v-for="(product, i) in products">
                                    <main-reviews-best-item-sub v-bind="{ product }" :index="(i + 4).toString().padStart(2, '0')" :review="product?.review__best" :key="i" />
                                    <v-divider :key="`${i}-divider`" />
                                </template>
                            </template>
                        </main-displays>
                    </div>
                </div>
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { mapGetters } from "vuex";
import { DISPLAY_CODES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import MainDisplays from "../main-displays.vue";
import MainReviewsBestItem from "./main-reviews-best-item.vue";
import MainReviewsBestItemSub from "./main-reviews-best-item-sub.vue";

const code = DISPLAY_CODES.BEST_REVIEW.value;
const sort = JSON.stringify({ index: 1 });

export default {
    props: {},
    components: {
        PageSection,
        MainDisplays,
        MainReviewsBestItem,
        MainReviewsBestItemSub,
    },
    data: () => ({
        code,
        sort,
    }),
    computed: {
        ...mapGetters(["displayCodes"]),
        CODE() {
            return this.displayCodes.find(({ value }) => value == code);
        },
    },
};
</script>

<style lang="scss" scoped></style>
