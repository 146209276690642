<template>
    <div class="py-16px">
        <router-link :to="`/shop/products/${product._id}`">
            <v-row align="center" class="flex-nowrap">
                <v-col cols="auto" style="flex: 0 0 114px">
                    <v-card width="100%">
                        <div class="square-img" :style="`background-image: url(${product?.thumb?.url})`">
                            <div class="square-img__badge square-img__badge--sm grey darken-4">{{ index }}</div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="auto" style="flex: 0 0 calc(100% - 114px); max-width: calc(100% - 114px)">
                    <div class="txt txt--sm txt--dark font-weight-bold text-truncate" v-if="product?.flags?.length">{{ product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                    <div class="txt txt--sm txt--dark text-truncate mt-4px">{{ product?.name }}</div>
                    <div class="mt-6px mt-md-10px">
                        <div class="d-flex align-center">
                            <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="product?.meta?.reviews?.star__average || 0" />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </router-link>
    </div>
</template>

<script>
import { initProduct, initShopReview } from "@/assets/variables";


export default {
    components: {
    },
    props: {
        index: { type: String, default: "04" },
        review: { type: Object, default: initShopReview },
        product: { type: Object, default: initProduct },
    },
};
</script>

<style>
</style>