var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1030 / 446
    }
  }, [_c('v-card', {
    staticClass: "h-100 rounded-b-0 overflow-hidden",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "w-md-230px h-100 grey lighten-5"
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px"
  }, [_c('v-btn', {
    staticClass: "transparent",
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "right": "0"
    },
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20",
      "color": "grey darken-4"
    }
  }, [_vm._v("mdi-close")])], 1), _c('br', {
    staticClass: "d-none d-lg-block d-xl-none"
  }), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("조이포라이프의 진심. 노약자, 장애인을 위한 의료보조기구 엔젤케어 시리즈 3종")]), _c('div', {
    staticClass: "mt-20px mt-md-auto"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "to": "/shop/products?category=angelcare"
    }
  }, 'v-btn', _vm.asideBtnAttrs, false), [_vm._v("엔젤케어 보러가기")])], 1)], 1)])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px"
  }, [_c('tit-wrap-subtitle', {
    attrs: {
      "titSize": "xs"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("케어 제품")];
      },
      proxy: true
    }, {
      key: "subTit",
      fn: function () {
        return [_vm._v("조이포라이프의 진심")];
      },
      proxy: true
    }])
  }), _c('main-visual-tab-care-banners', {
    attrs: {
      "code": _vm.BANNERS.MAIN_6.code__pc,
      "isPc": ""
    }
  }), _c('main-visual-tab-care-banners', {
    attrs: {
      "code": _vm.BANNERS.MAIN_6.code__mb,
      "isMb": ""
    }
  })], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }