var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-displays', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var products = _ref.products;
        return [_c('v-row', [_vm._l(products, function (product, index) {
          var _product$thumb, _product$flags, _product$meta, _product$meta$reviews, _product$meta2, _product$meta2$review;
          return [_c('v-col', {
            key: index,
            attrs: {
              "cols": "12"
            }
          }, [_c('router-link', {
            attrs: {
              "to": `/shop/products/${product._id}`
            }
          }, [_c('v-row', {
            staticClass: "flex-nowrap",
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', {
            staticStyle: {
              "flex": "0 0 152px"
            },
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-card', {
            attrs: {
              "width": "100%"
            }
          }, [_c('div', {
            staticClass: "square-img",
            style: `background-image: url(${product === null || product === void 0 ? void 0 : (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.url})`
          })])], 1), _c('v-col', {
            staticStyle: {
              "flex": "0 0 calc(100% - 152px)",
              "max-width": "calc(100% - 152px)"
            },
            attrs: {
              "cols": "auto"
            }
          }, [product !== null && product !== void 0 && (_product$flags = product.flags) !== null && _product$flags !== void 0 && _product$flags.length ? _c('div', {
            staticClass: "txt txt--sm txt--dark font-weight-bold text-truncate"
          }, [_vm._v(_vm._s(product.flags.map(function (text) {
            return `[${text}]`;
          }).join(" ")))]) : _vm._e(), _c('div', {
            staticClass: "txt txt--sm txt--dark text-truncate mt-4px"
          }, [_vm._v(_vm._s(product.name))]), _c('div', {
            staticClass: "mt-6px mt-md-10px"
          }, [_c('div', {
            staticClass: "d-flex align-center"
          }, [_c('v-rating', {
            attrs: {
              "half-increments": "",
              "readonly": "",
              "size": "18",
              "color": "grey darken-4",
              "background-color": "grey darken-4",
              "value": (product === null || product === void 0 ? void 0 : (_product$meta = product.meta) === null || _product$meta === void 0 ? void 0 : (_product$meta$reviews = _product$meta.reviews) === null || _product$meta$reviews === void 0 ? void 0 : _product$meta$reviews.star__average) || 0
            }
          }), _c('span', {
            staticClass: "font-size-14 grey--text text--lighten-1 pl-4px"
          }, [_vm._v("(" + _vm._s((product === null || product === void 0 ? void 0 : (_product$meta2 = product.meta) === null || _product$meta2 === void 0 ? void 0 : (_product$meta2$review = _product$meta2.reviews) === null || _product$meta2$review === void 0 ? void 0 : _product$meta2$review.count__total) || 0) + ")")])], 1)]), _c('div', {
            staticClass: "mt-8px mt-md-10px"
          }, [_vm.getDiscountPrice(product) ? _c('span', {
            staticClass: "font-size-14 grey--text text--lighten-1 text-decoration-line-through"
          }, [_vm._v(_vm._s(product.price.format()))]) : _vm._e(), !product.sells || product.stock == 0 ? _c('em', {
            staticClass: "font-size-14 font-size-md-16 grey--text text--lighten-1"
          }, [_vm._v("품절")]) : _vm._e(), _c('div', {
            staticClass: "d-flex flex-wrap align-center font-size-14 font-size-lg-16 font-weight-bold"
          }, [_vm.getDiscountRate(product) ? _c('span', {
            staticClass: "secondary--text mr-4px mr-md-8px"
          }, [_vm._v(" " + _vm._s(_vm.getDiscountRate(product)) + "% ")]) : _vm._e(), _c('span', {
            staticClass: "d-flex flex-wrap align-center grey--text text--darken-4"
          }, [_vm._v(" " + _vm._s(_vm.getSalePrice(product).format()) + " "), _c('small', {
            staticClass: "font-size-14 font-weight-regular pl-2px"
          }, [_vm._v("원")])])])])])], 1)], 1)], 1)];
        })], 2)];
      }
    }])
  }, 'main-displays', {
    code: _vm.code
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }