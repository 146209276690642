<template>
    <v-menu class="family-site-list">
        <template #activator="{ on, attrs }">
            <v-select v-bind="{ ...attrs_input, attrs }" v-on="on" menu-props="openOnClick: false" item-disabled dense color="grey" placeholder="Family Site" class="white--text" />
        </template>

        <v-list class="pa-0">
            <template v-for="item in items">
                <v-list-item :key="item" :href="item.link" target="_blank">
                    {{ item.name }}
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>

<script>
import { attrs_input } from "@/assets/variables";

export default {
    data: () => ({
        attrs_input,
        items: [
            {
                name: "JOEYFORLIFE 1.0",
                link: "http://joeyforlife.pagecheck.co.kr/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.family-site-list {
    z-index: 999;
}
</style>