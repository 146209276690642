var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.accessToken ? _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "주문내역"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-underline",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey",
      "to": "/mypage/orders"
    }
  }, [_vm._v("더 보기")])], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item._id,
      attrs: {
        "cols": "12"
      }
    }, [_c('order-view', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var _item$product, _item$product$thumb, _item$product2;
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-card', _vm._g(_vm._b({}, 'v-card', attrs, false), on), [_c('v-row', {
            staticClass: "flex-nowrap",
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-card', {
            attrs: {
              "width": "94"
            }
          }, [_c('div', {
            staticClass: "square-img",
            style: `background-image: url(${item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url})`
          })])], 1), _c('v-col', {
            staticStyle: {
              "max-width": "calc(100% - 114px)",
              "flex": "0 0 calc(100% - 114px)"
            },
            attrs: {
              "cols": ""
            }
          }, [_c('div', {
            staticClass: "txt txt--sm txt--dark font-weight-bold text-truncate"
          }, [_vm._v("[5%할인특가] [회원가입]")]), _c('div', {
            staticClass: "txt txt--sm txt--dark text-truncate mt-4px"
          }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : _item$product2.name))]), _c('div', {
            staticClass: "txt txt--xs txt--light text-truncate mt-12px"
          }, [_vm._v(_vm._s(_vm.$getStatusText(item)))])])], 1)], 1)];
        }
      }], null, true),
      model: {
        value: item.order,
        callback: function ($$v) {
          _vm.$set(item, "order", $$v);
        },
        expression: "item.order"
      }
    })], 1)];
  }), !_vm.items.length ? [_c('v-col', {
    staticClass: "text-center"
  }, [_vm._v(" 주문내역이 없습니다 ")])] : _vm._e()], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }