var render = function render(){
  var _vm$CODE, _vm$CODE2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-row', {
    staticClass: "mx-n10px my-n10px mx-md-n20px mx-lg-n30px"
  }, [_c('v-col', {
    staticClass: "d-lg-none px-10px py-10px px-md-20px px-lg-30px",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(_vm._s((_vm$CODE = _vm.CODE) === null || _vm$CODE === void 0 ? void 0 : _vm$CODE.text))]), _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-135"
  }, [_vm._v("고객님들의 생생한 리뷰를 확인해 보세요!")])])]), _c('v-col', {
    staticClass: "px-10px py-10px px-md-20px px-lg-30px",
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'max-width: calc(100% - 340px);' : '',
    attrs: {
      "cols": "12",
      "lg": "",
      "order-lg": "2"
    }
  }, [_c('main-displays', _vm._b({
    attrs: {
      "skip": "0",
      "limit": "3"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var products = _ref.products;
        return [_c('v-row', [_vm._l(products, function (product, i) {
          return [_c('main-reviews-best-item', _vm._b({
            key: i,
            attrs: {
              "index": (i + 1).toString().padStart(2, '0'),
              "review": product === null || product === void 0 ? void 0 : product.review__best
            }
          }, 'main-reviews-best-item', {
            product
          }, false))];
        })], 2)];
      }
    }])
  }, 'main-displays', {
    code: _vm.code,
    sort: _vm.sort
  }, false))], 1), _c('v-col', {
    staticClass: "px-10px py-10px px-md-20px px-lg-30px",
    attrs: {
      "cols": "12",
      "lg": "auto",
      "order-lg": "1"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mw-lg-280px h-100"
  }, [_c('div', {
    staticClass: "tit-wrap d-none d-lg-block"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(_vm._s((_vm$CODE2 = _vm.CODE) === null || _vm$CODE2 === void 0 ? void 0 : _vm$CODE2.text))]), _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-135"
  }, [_vm._v("고객님들의 생생한 리뷰를 확인해 보세요!")])]), _c('div', {
    staticClass: "mt-auto"
  }, [_c('v-divider'), _c('main-displays', _vm._b({
    attrs: {
      "skip": "3",
      "limit": "3"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var products = _ref2.products;
        return [_vm._l(products, function (product, i) {
          return [_c('main-reviews-best-item-sub', _vm._b({
            key: i,
            attrs: {
              "index": (i + 4).toString().padStart(2, '0'),
              "review": product === null || product === void 0 ? void 0 : product.review__best
            }
          }, 'main-reviews-best-item-sub', {
            product
          }, false)), _c('v-divider', {
            key: `${i}-divider`
          })];
        })];
      }
    }])
  }, 'main-displays', {
    code: _vm.code,
    sort: _vm.sort
  }, false))], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }