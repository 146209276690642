<template>
    <page-section>
        <v-row class="row--main-exhibition">
            <v-col cols="12">
                <main-exhibition-banner :code="BANNERS.MAIN_3.code__pc" isPc />
                <main-exhibition-banner :code="BANNERS.MAIN_3.code__mb" isMb />
            </v-col>
            <v-col cols="12">
                <v-row :style="$vuetify.breakpoint.mdAndUp ? 'height:calc(100% + 20px)' : ''" class="row--exhibition-card">
                    <v-col cols="12" sm="6" xl="12">
                        <v-card color="#E1E5E8" to="/center/delivery-installation-service" class="exhibition-card" style="background-image: url('/images/main/onestop-bg.png')">
                            <v-responsive :aspect-ratio="655 / 240">
                                <div class="exhibition-card__inner">
                                    <div class="mb-4px mb-md-8px">
                                        <v-chip small color="primary"><b>One-stop Service</b></v-chip>
                                    </div>
                                    <div class="tit-wrap mb-0">
                                        <h2 class="tit font-size-lg-26 font-size-xl-36">원스탑 서비스</h2>
                                        <p class="txt txt--sm txt--dark line-height-135 mt-6px mt-xl-12px">주문·배송·설치까지 한번에 제공해주는 토탈 서비스</p>
                                        <div class="pt-12px pt-md-20px">
                                            <v-btn small text color="grey darken-4" class="v-btn--text-fit">더 보기<v-icon class="pl-4px">mdi-chevron-right</v-icon></v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" xl="12">
                        <v-card color="#EEEEEE" to="/shop/exhibitions?group=BARGAIN_SALES" class="exhibition-card" style="background-image: url('/images/main/exclusive-bg.png')">
                            <v-responsive :aspect-ratio="655 / 240">
                                <div class="exhibition-card__inner">
                                    <div class="mb-4px mb-md-8px">
                                        <v-chip small color="secondary"><b>Exclusive Sale</b></v-chip>
                                    </div>
                                    <div class="tit-wrap mb-0">
                                        <h2 class="tit font-size-lg-26 font-size-xl-36">온라인 특가 상품</h2>
                                        <p class="txt txt--sm txt--dark line-height-135 mt-6px mt-xl-12px">조이포라이프 스페셜 특가 제품 모음</p>
                                        <div class="pt-12px pt-md-20px">
                                            <v-btn small text color="grey darken-4" class="v-btn--text-fit">더 보기<v-icon class="pl-4px">mdi-chevron-right</v-icon></v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { BANNERS } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import MainExhibitionBanner from "./main-exhibition-banner.vue";

export default {
    props: {},
    components: {
        PageSection,
        MainExhibitionBanner,
    },
    data: () => ({
        BANNERS,
    }),
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// row
// row main-exhibition
@media (min-width: 1500px) {
    .row--main-exhibition {
        > [class*="col"] {
            &:nth-child(1) {
                flex: 0 0 58.3333333333%;
                max-width: 58.3333333333%;
            }
            &:nth-child(2) {
                flex: 0 0 41.6666666667%;
                max-width: 41.6666666667%;
            }
        }
    }
}
@media (min-width: 1700px) {
    .row--main-exhibition {
        > [class*="col"] {
            &:nth-child(1),
            &:nth-child(2) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}
// row exhibition-card
@media (min-width: 1200px) {
    .row--exhibition-card {
        > [class*="col"] {
            &:nth-child(1),
            &:nth-child(2) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}
@media (min-width: 1500px) {
    .row--exhibition-card {
        > [class*="col"] {
            &:nth-child(1),
            &:nth-child(2) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
// card
.exhibition-card {
    height: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    &__inner {
        height: 100%;
        padding: 30px 20px;
    }
}
@media (min-width: 768px) {
    .exhibition-card {
        &__inner {
            padding: 30px;
        }
    }
}
@media (min-width: 1024px) {
    .exhibition-card {
        &__inner {
            padding: 20px;
        }
    }
}
@media (min-width: 1500px) {
    .exhibition-card {
        &__inner {
            padding: 40px;
        }
    }
}
@media (max-width: 1700px) {
    .exhibition-card {
        background-image: none !important;
    }
}
</style>
