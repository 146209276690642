<template>
    <header class="header">
        <!-- S: PC -->
        <div class="header__inner">
            <div class="header-head grey lighten-5">
                <v-container class="container--lg">
                    <!-- <main-header-notice /> -->
                    <v-row align="center">
                        <v-col cols="auto">
                            <div class="divider-group divider-group--lg">
                                <ul>
                                    <li>
                                        <v-row align="center">
                                            <v-col cols="auto">
                                                <v-chip x-small color="primary" class="font-weight-bold" href="http://joeyforlife.pagecheck.co.kr/" target="_blank">Joeyforlife 1.0</v-chip>
                                            </v-col>
                                            <!-- <v-col cols="auto">
                                                <v-btn x-small text color="grey darken-4" class="v-btn--text-fit font-weight-regular">Bookmark</v-btn>
                                            </v-col> -->
                                            <v-col cols="auto">
                                                <v-btn x-small text color="grey darken-4" to="/VR-showroom" class="v-btn--text-fit font-weight-regular">VR Showroom</v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn x-small text color="grey darken-4" to="/3d-interir" class="v-btn--text-fit font-weight-regular">3D Interior</v-btn>
                                            </v-col>
                                        </v-row>
                                    </li>
                                    <li>
                                        <v-row align="center">
                                            <v-col cols="auto">
                                                <v-btn href="https://www.youtube.com/@joeyforlife/featured" target="_blank" text class="v-btn--text-fit"><i class="icon icon-youtube"></i></v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn href="https://www.instagram.com/joeyforlifemall" target="_blank" text class="v-btn--text-fit"><i class="icon icon-instagram"></i></v-btn>
                                            </v-col>
                                        </v-row>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <v-row align="center">
                                <v-col cols="auto">
                                    <v-btn x-small text color="grey darken-4" to="/shop/showrooms" class="v-btn--text-fit font-weight-medium">온라인 쇼룸</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small text color="grey darken-4" to="/center/notice" class="v-btn--text-fit font-weight-medium">고객센터</v-btn>
                                </v-col>
                            </v-row>
                            <!-- <div class="divider-group">
                                <ul>
                                    <li>
                                        <router-link to="/" class="grey--text text--darken-4"><span>마이오피스</span></router-link>
                                    </li>
                                    <li v-if="!accessToken">
                                        <router-link to="/login"><span>로그인</span></router-link>
                                    </li>
                                    <li v-else><a href="#" @click="logout">로그아웃</a></li>
                                    <li v-if="!accessToken">
                                        <router-link to="/join"><span>회원가입</span></router-link>
                                    </li>
                                    <li><router-link to="/center/faq">자주묻는 질문</router-link></li>
                                    <li><router-link to="/">전자e카달로그</router-link></li>
                                </ul>
                            </div> -->
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <div class="header-body">
                <v-container class="container--lg">
                    <div class="header-contents">
                        <div class="w-100 mw-1330px">
                            <v-row class="row--lg">
                                <v-col cols="auto">
                                    <h1 class="logo">
                                        <router-link to="/" title="홈으로 이동"><v-img :src="logo" /></router-link>
                                    </h1>
                                </v-col>
                                <v-col>
                                    <div class="header-search">
                                        <div class="header-search__form d-flex align-center">
                                            <v-text-field v-model="searchValue" placeholder="제품 또는 브랜드를 검색해 주세요." solo hide-details class="ma-0 pc-search-input" @focus="searchFocused = true" @blur="onSearchBlur" @keydown.enter="search">
                                                <template #prepend-inner v-if="!searchFocused">
                                                    <button class="ml-2 mr-3">
                                                        <icon-search />
                                                    </button>
                                                </template>
                                                <template #append v-if="searchFocused">
                                                    <button class="ml-3 mr-2" @click="search">
                                                        <icon-search />
                                                    </button>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="header-user-menu">
                            <ul>
                                <!-- <li v-if="!accessToken">
                                    <router-link class="link" to="/mypage/order-list">
                                        <icon-person />
                                        <span class="header-user-menu__txt">비회원주문조회</span>
                                    </router-link>
                                </li> -->
                                <li v-if="accessToken">
                                    <div class="link cursor-pointer" @click="logout">
                                        <icon-logout />
                                    </div>
                                </li>
                                <li v-if="accessToken">
                                    <router-link class="link" to="/mypage/dashboard">
                                        <icon-person />
                                        <!-- <span class="header-user-menu__txt">마이페이지</span> -->
                                    </router-link>
                                </li>
                                <li v-else>
                                    <router-link class="link" to="/join">
                                        <icon-person />
                                        <span class="header-user-menu__txt d-none d-xl-block">회원가입 시 즉시 5% 할인!</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link class="link" to="/mypage/likes">
                                        <span class="material-icons-outlined">favorite_border</span>
                                    </router-link>
                                </li>
                                <li>
                                    <main-cart-button to="/mypage/cart">
                                        <template v-slot:default="{ carts }">
                                            <!-- <em class="badge">{{ carts.length }}</em> -->
                                            <span>
                                                <v-badge :value="carts.length != 0" dot bordered overlap color="primary">
                                                    <icon-shopping-bag />
                                                </v-badge>
                                            </span>
                                        </template>
                                    </main-cart-button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-container>
            </div>

            <div class="header-foot">
                <v-container class="container--lg">
                    <v-row no-gutters>
                        <v-col cols="auto">
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <div class="header-category">
                                        <button type="button" id="category" class="header-category__button">
                                            <span class="header-category__hamburger">
                                                <span class="header-category__hamburger-line"><span class="d-sr-only">전체 카테고리</span></span>
                                            </span>
                                        </button>

                                        <!-- 카테고리 리스트 -->
                                        <div class="header-category__full">
                                            <main-shop-categories pc></main-shop-categories>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="auto">
                                    <div class="header-menu">
                                        <main-shop-categories menu></main-shop-categories>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <div class="header-menu d-block">
                                <ul class="header-menu__lists">
                                    <li class="header-menu__list">
                                        <router-link :to="{ path: '/shop', query: { groups: DISPLAY_CODES.BEST_SELLER.value } }" class="header-menu__list-link">베스트</router-link>
                                    </li>
                                    <li v-for="{ text, value } in groups" :key="value" class="header-menu__list">
                                        <router-link :to="{ path: '/shop/exhibitions', query: { group: value } }" class="header-menu__list-link">{{ text }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <!-- E: PC -->

        <!-- S: Mobile -->
        <!-- mobile-header -->
        <div class="mobile-header">
            <h1 class="logo">
                <router-link to="/" title="홈으로 이동" style="max-width: 46vw"><v-img :src="logo" /></router-link>
            </h1>
            <!-- <ul class="mobile-header__customer">
                <li>
                    <router-link v-if="!accessToken" to="/login">로그인</router-link>
                    <a href="#" v-else @click="logout">로그아웃</a>
                </li>
                <li>
                    <router-link v-if="!accessToken" to="/mypage/order-list">비회원주문조회</router-link>
                    <router-link v-else to="/mypage/myinfo">마이페이지</router-link>
                </li>
            </ul> -->
            <button class="mobile-header__search"><icon-search /><span class="d-sr-only">상품검색창열기</span></button>
            <main-shop-categories-mobile />
        </div>
        <!-- mobile-searchbar -->
        <div class="mobile-searchbar">
            <div class="mobile-searchbar__head">
                <button class="mobile-searchbar__close" type="button"><span class="d-sr-only">모바일 검색창 닫기</span></button>
                <div class="mobile-searchbar__form">
                    <input v-model="searchValue" class="mobile-searchbar__input" type="text" name="searchValue" placeholder="검색어를 입력하세요." @keydown.enter="search" />
                    <button class="mobile-searchbar__button" name="button" @click="search"><icon-search /><span class="d-sr-only">검색</span></button>
                </div>
            </div>
        </div>
        <!-- mobile-hamburger -->
        <div class="mobile-hamburger">
            <button type="button" aria-pressed="false" class="mobile-hamburger__btn">
                <span class="mobile-hamburger__line"></span>
            </button>
        </div>
        <!-- mobile-sidebar -->
        <div class="mobile-sidebar">
            <div class="mobile-sidebar-head">
                <button class="mobile-sidebar__close" type="button"><span class="d-sr-only">모바일 메뉴바 닫기</span></button>
                <div class="mobile-sidebar__info">
                    <router-link v-if="!accessToken" class="mobile-sidebar__member" to="/login">로그인 해주세요</router-link>
                    <a href="#" v-else @click="logout" class="mobile-sidebar__member">로그아웃</a>
                </div>
            </div>

            <div class="mobile-sidebar-body">
                <ul class="mobile-sidebar__mylink">
                    <li v-if="accessToken">
                        <!-- <router-link v-if="!accessToken" class="link" to="/mypage/order-list">
                            <icon-person />
                            <span>비회원주문조회</span>
                        </router-link> -->
                        <router-link class="link" to="/mypage/myinfo">
                            <icon-person />
                            <span>마이페이지</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/mypage/cart">
                            <icon-shopping-bag />
                            장바구니
                        </router-link>
                    </li>
                </ul>
                <ul class="mobile-sidebar__link">
                    <li>
                        <router-link class="link" :to="{ path: '/shop', query: { groups: DISPLAY_CODES.BEST_SELLER.value } }">베스트<v-icon>mdi-chevron-right</v-icon></router-link>
                    </li>
                    <li v-for="{ text, value } in groups" :key="value">
                        <router-link class="link" :to="{ path: '/shop/exhibitions', query: { group: value } }">{{ text }}<v-icon>mdi-chevron-right</v-icon> </router-link>
                    </li>
                    <!-- <li>
                        <router-link class="link" to="/center/faq">자주묻는 질문<v-icon>mdi-chevron-right</v-icon></router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/shop/brand-list">브랜드<v-icon>mdi-chevron-right</v-icon></router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/">전자e카달로그<v-icon>mdi-chevron-right</v-icon></router-link>
                    </li> -->
                </ul>

                <main-shop-categories mobile></main-shop-categories>
            </div>
        </div>
        <div class="mobile-header-dimmed"></div>
        <!-- E: Mobile -->
    </header>
</template>

<script>
import api from "@/api";
import { DISPLAY_CODES, EXHIBITION_GROUPS } from "@/assets/variables";

import IconSearch from "@/components/client/icons/icon-search.vue";
import IconPerson from "@/components/client/icons/icon-person.vue";
import IconShoppingBag from "@/components/client/icons/icon-shopping-bag.vue";
import IconLogout from "@/components/client/icons/icon-logout.vue";

import MainCartButton from "../main/main-cart-button.vue";
import MainHeaderNotice from "./main-header-notice.vue";
import MainShopCategories from "../main/main-shop-categories.vue";
import MainShopCategoriesMobile from "../main/main-shop-categories-mobile.vue";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        IconSearch,
        IconPerson,
        IconShoppingBag,
        IconLogout,

        MainCartButton,
        MainHeaderNotice,
        MainShopCategories,
        MainShopCategoriesMobile,
    },
    data: () => ({
        searchValue: null,
        searchFocused: false,

        groups,
        DISPLAY_CODES,
        logo: null,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { searchValue } = this.$route?.query || {};
            this.searchValue = searchValue;

            let { setting } = await api.v1.setting.get();
            this.logo = setting.logo.src;

            this.$nextTick(function () {
                this.header();
            });
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
        search() {
            this.$router.push(`/shop/products?searchValue=${this.searchValue || ""}`);
        },
        onSearchBlur(/** @type {FocusEvent} */ event) {
            setTimeout(() => {
                this.searchFocused = event.target.id == document.activeElement.id;
            }, 200);
        },

        // header
        header: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                    hamburgerCreate();
                }
            }

            // Mobile Header
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                searchBtn = document.querySelector(".mobile-header__search"),
                closeSidebar = document.querySelector(".mobile-sidebar__close"),
                closeSearchbar = document.querySelector(".mobile-searchbar__close"),
                dimmed = document.querySelector(".mobile-header-dimmed"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");

            hamburgerBtn.addEventListener("click", hamburgerDestroy);
            searchBtn.addEventListener("click", searchDestroy);
            closeSidebar.addEventListener("click", hamburgerCreate);
            closeSearchbar.addEventListener("click", searchCreate);
            dimmed.addEventListener("click", hamburgerCreate);
            dimmed.addEventListener("click", searchCreate);
            mobileCategory.forEach(function (el) {
                el.addEventListener("click", toggleAccordion);
            });

            // Mobile Hamburger
            function hamburgerCreate() {
                let attr = header.getAttribute("data-dimmed");
                if (attr !== null) {
                    hamburgerBtn.setAttribute("aria-pressed", "false");
                    header.setAttribute("data-sidebar", "closed");
                    header.setAttribute("data-dimmed", "closed");
                    mobileCategory.forEach((element) => {
                        element.setAttribute("data-toggle", "closed");
                    });
                }
            }
            function hamburgerDestroy() {
                hamburgerBtn.setAttribute("aria-pressed", "true");
                header.setAttribute("data-sidebar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }
            // Mobile Search
            function searchCreate() {
                // HTML.removeAttribute("data-scroll");
                header.setAttribute("data-searchbar", "closed");
                header.setAttribute("data-dimmed", "closed");
            }
            function searchDestroy() {
                // HTML.setAttribute("data-scroll", "false");
                header.setAttribute("data-searchbar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }

            // Mobile
            function toggleAccordion(el) {
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");
                var targetLi = el.currentTarget.parentNode.classList;
                // var target = el.currentTarget;

                el.preventDefault();
                if (targetLi.contains("opened")) {
                    targetLi.remove("opened");
                } else {
                    mobileCategory.forEach(function (el) {
                        el.parentNode.classList.remove("opened");
                    });
                    targetLi.add("opened");
                }
            }
        },
        closeSidebar() {
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");
            let attr = header.getAttribute("data-dimmed");
            if (attr !== null) {
                hamburgerBtn.setAttribute("aria-pressed", "false");
                header.setAttribute("data-sidebar", "closed");
                header.setAttribute("data-dimmed", "closed");
                mobileCategory.forEach((element) => {
                    element.setAttribute("data-toggle", "closed");
                });
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    watch: {
        "$route.query.searchValue"() {
            this.init();
        },
        "$route.query"() {
            this.closeSidebar();
        },
    },
};
</script>

<style lang="scss" scoped>
.pc-search-input {
    ::v-deep .v-input__append-inner {
        margin: 0;
    }
}
</style>