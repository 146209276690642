var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('main-aside-banner'), _c('main-aside-login'), _c('main-aside-me'), _c('main-aside-purchases'), _c('main-aside-carts'), _c('main-aside-likes'), _c('main-aside-views'), _c('main-aside-services'), _c('main-aside-installation'), _c('main-aside-go-to-top')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }