var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "고객서비스"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-underline",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey",
      "to": "/center/notice"
    }
  }, [_vm._v("더 보기")])], 1), _c('v-card', {
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px"
  }, [_c('v-row', {
    staticClass: "row--sm txt txt--xs txt--dark font-weight-medium text-center",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('a', {
    staticClass: "d-flex flex-column justify-center",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showMessenger.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-20"
  }, [_vm._v("chat")]), _c('div', {
    staticClass: "mt-8px"
  }, [_vm._v("실시간 채팅")])])]), _c('v-col', {
    staticClass: "align-self-stretch",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-100",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('router-link', {
    staticClass: "d-flex flex-column justify-center",
    attrs: {
      "to": "/mypage/questions"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-20"
  }, [_vm._v("person")]), _c('div', {
    staticClass: "mt-8px"
  }, [_vm._v("1:1 상담")])])], 1), _c('v-col', {
    staticClass: "align-self-stretch",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-100",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('router-link', {
    staticClass: "d-flex flex-column justify-center",
    attrs: {
      "to": "/center/notice"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined font-size-20"
  }, [_vm._v("call")]), _c('div', {
    staticClass: "mt-8px"
  }, [_vm._v("고객센터")])])], 1)], 1)], 1)]), _c('v-card', {
    staticClass: "mt-12px",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_c('v-row', {
    staticClass: "row--sm txt txt--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("전화")])]), _c('v-col', [_c('div', [_vm._v("070-4010-5061​")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("메일")])]), _c('v-col', [_c('div', [_vm._v("jflmall@joeyforlife.com​")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("평일")])]), _c('v-col', [_c('div', [_vm._v("오전 08:00 - 오후 05:00​")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("점심")])]), _c('v-col', [_c('div', [_vm._v("오후 12:00 - 오후 01:00​")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("휴무")])]), _c('v-col', [_c('div', [_vm._v("토/일/공휴일은 휴무​")])])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }