var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('v-btn', _vm._g(_vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, _vm.$attrs), false), {
    click: _vm.click
  }), [_vm._v(" GO TO TOP "), _c('icon-arrow-rounded', {
    staticClass: "ml-12px",
    attrs: {
      "direction": "up"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }