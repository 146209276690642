<template>
    <client-page class="app--main">
        <!-- S: 띠배너 -->
        <template #bandBanner> <main-band-banners /> </template>
        <!-- E: 띠배너 -->

        <template #contentsImmersive>
            <div class="top-link">
                <v-container class="container--lg">
                    <ul class="top-link__lists">
                        <li class="top-link__list">
                            <router-link :to="{ path: '/shop', query: { groups: DISPLAY_CODES.BEST_SELLER.value } }" class="top-link__list-link">베스트</router-link>
                        </li>
                        <li class="top-link__list">
                            <router-link to="/shop/exhibitions?group=BARGAIN_SALES" class="top-link__list-link">특가</router-link>
                        </li>
                        <li class="top-link__list">
                            <router-link to="/shop/exhibitions?group=PLANNED_SALES" class="top-link__list-link">프로모션</router-link>
                        </li>
                        <!-- <li class="top-link__list">
                            <router-link to="/shop/exhibitions?group=POP_UP_EVENTS" class="top-link__list-link">Weekly</router-link>
                        </li> -->
                        <li class="top-link__list">
                            <router-link to="/community/snse" class="top-link__list-link">SNS이벤트</router-link>
                        </li>
                        <!-- <li v-for="{ text, value } in groups" :key="value" class="top-link__list">
                            <router-link :to="{ path: '/shop/exhibitions', query: { group: value } }" class="top-link__list-link">{{ text }}</router-link>
                        </li> -->
                    </ul>
                </v-container>
            </div>
        </template>

        <!-- S: 메인비주얼, 배너 -->
        <main-visual class="page-section--first pb-0" />
        <!-- E: 메인비주얼, 배너 -->

        <!-- S: 이달의 인기상품 -->
        <main-products-popular />
        <!-- E: 이달의 인기상품 -->

        <!-- S: 기획전, 특가 -->
        <v-divider />
        <main-exhibition />
        <!-- E: 기획전, 특가 -->

        <!-- S: 쇼룸, 서비스 -->
        <v-divider />
        <main-showroom-service />
        <!-- E: 쇼룸, 서비스 -->

        <!-- S: 베스트 10 제품 -->
        <v-divider />
        <main-products-best />
        <!-- E: 베스트 10 제품 -->

        <!-- S: 베스트 리뷰 -->
        <v-divider />
        <main-reviews-best class="page-section--last" />
        <!-- E: 베스트 리뷰 -->

        <popup-layer v-for="popup in popups" :key="popup._id" :popup="popup" />
    </client-page>
</template>

<script>
import { DISPLAY_CODES, EXHIBITION_GROUPS } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainReviewsBest from "@/components/client/main/main-reviews-best/main-reviews-best.vue";
import MainBandBanners from "@/components/client/main/main-band-banners/main-band-banners.vue";
import MainProductsBest from "@/components/client/main/main-products-best/main-products-best.vue";
import MainProductsPopular from "@/components/client/main/main-products-popular/main-products-popular.vue";
import MainVisual from "@/components/client/main/main-visual/main-visual.vue";
import MainExhibition from "@/components/client/main/main-exhibition/main-exhibition.vue";
import MainShowroomService from "@/components/client/main/main-showroom-service.vue";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        ClientPage,
        PopupLayer,
        MainReviewsBest,
        MainBandBanners,
        MainProductsBest,
        MainProductsPopular,
        MainVisual,
        MainExhibition,
        MainShowroomService,
    },
    data: () => ({
        popups: [],

        groups,
        DISPLAY_CODES,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPopups();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");
                    this.popups = res.data.popups;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input--selection-controls .v-input__slot > .v-label,
    .v-input--selection-controls .v-radio > .v-label {
        display: inline-block;
    }
}

// top link
.top-link {
    background-color: #182954;
    &__lists {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 0;
            background-color: #182954;
        }
    }
    &__list {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(255, 255, 255, 0.5);
        }
        &:first-child::before {
            display: none;
        }
    }
    &__list-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        line-height: 1.2;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding: 4px 14px;
        transition: all ease-out 0.2s;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .top-link {
        &__lists {
            // padding-left: 52px;
            > .top-link__list:first-child {
                margin-left: -20px;
            }
            &::after {
                display: none;
            }
        }
        &__list-link {
            height: 52px;
            padding: 6px 20px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 1024px) {
    .top-link {
        .container {
            padding: 0;
        }
    }
}
</style>
