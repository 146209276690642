<template>
    <page-section>
        <v-row class="mx-n10px my-n10px mx-md-n20px mx-lg-n30px">
            <v-col cols="12" lg="auto" class="px-10px py-10px px-md-20px px-lg-30px">
                <div class="mw-lg-280px">
                    <div class="tit-wrap">
                        <h2 class="tit tit--lg">{{ CODE.text }}</h2>
                        <p class="txt txt--sm txt--dark line-height-135">이번 달 가장 핫한 제품만 모았습니다!</p>
                        <div class="pt-12px pt-md-20px">
                            <v-btn small text color="primary" class="v-btn--text-fit" :to="{ path: '/shop', query: { groups: code } }">더 보기<v-icon class="pl-4px">mdi-chevron-right</v-icon></v-btn>
                        </div>
                    </div>
                    <div>
                        <main-products-popular-categories v-bind="{ code }" />
                    </div>
                </div>
            </v-col>
            <v-col cols="12" lg="" class="px-10px py-10px px-md-20px px-lg-30px" :style="$vuetify.breakpoint.lgAndUp ? 'max-width: calc(100% - 340px);' : ''">
                <main-products-popular-displays v-bind="{ code }" />
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { mapGetters } from "vuex";
import { DISPLAY_CODES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import MainProductsPopularDisplays from "./main-products-popular-displays.vue";
import MainProductsPopularCategories from "./main-products-popular-categories.vue";

const code = DISPLAY_CODES.POPULAR.value;

export default {
    components: {
        PageSection,
        MainProductsPopularDisplays,
        MainProductsPopularCategories,
    },
    data: () => ({
        code,
    }),
    computed: {
        ...mapGetters(["displayCodes"]),
        CODE() {
            return this.displayCodes.find(({ value }) => value == code);
        },
    },
};
</script>

<style lang="scss" scoped></style>
