import Vue from "vue";
import Vuex from "vuex";

import api from "@/api";
import axios from "@/plugins/axios";
import dayjs from "@/plugins/vue-dayjs";
// import CookieStorage from "@/plugins/cookie-storage";

import { DISPLAY_CODES, SHIPPING_CODES, SHIPPING_TARGET_TYPES, USER_LEVEL_DISCOUNT_UNITS, USER_LEVEL_DISCOUNT_TYPES } from "@/assets/variables/constants";
import { initSetting, initSettingShopDisplayCode, initSettingShopShippingCode } from "@/assets/variables/inits";

import { bandBanners } from "./band-banners";
import { enlipleTracker } from "./enliple-tracker";
import { naverWcs } from "./naver-wcs";
import { metaPixel } from "./meta-pixel";
import { googleTag } from "./google-tag";

Vue.use(Vuex);

// const cookieStorage = new CookieStorage();

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
var scope = payload?.scope || [];

var carts = JSON.parse(sessionStorage.getItem("carts") || "[]");
var pickup = JSON.parse(sessionStorage.getItem("pickup") || "[]");
var pickup__target = JSON.parse(sessionStorage.getItem("pickup__target") || "[]");
var watches = JSON.parse(sessionStorage.getItem("watches") || "[]");

var agreements = JSON.parse(sessionStorage.getItem("agreements") || "[]");

/** @type {import("vuex").StoreOptions} */
export default new Vuex.Store({
    state: {
        // user
        refreshToken,
        accessToken,
        payload,
        scope,
        user: undefined,

        // common
        setting: initSetting(),
        likes: undefined,

        // shop
        carts,
        pickup,
        pickup__target,
        watches,
        islands: [],
        categories: [],
        shipping__everyAsset: undefined,

        // join
        agreements,

        // ui
        main: { alert: true },
    },
    mutations: {
        // user
        login(state, { refreshToken, accessToken }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;

            state.payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
            state.scope = state.payload?.scope || [];
            state.likes = [];

            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("accessToken", accessToken);
        },
        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];
            state.user = undefined;
            state.likes = undefined;
            state.carts = [];
            state.pickup = [];
            state.pickup__target = [];
            state.watches = [];
            state.agreements = [];

            axios.defaults.headers.common["Authorization"] = "";

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("carts");
            sessionStorage.removeItem("pickup");
            sessionStorage.removeItem("pickup__target");
            sessionStorage.removeItem("watches");
            sessionStorage.removeItem("agreements");
        },
        setUser(state, { user }) {
            state.user = user;
        },

        // common
        setSetting(state, { setting }) {
            state.setting = initSetting(setting);
        },
        setLikes(state, { likes }) {
            state.likes = likes;
        },

        // shop
        setCarts(state, { carts }) {
            state.carts = carts;
            sessionStorage.setItem("carts", JSON.stringify(state.carts));
        },
        addToCart(state, carts) {
            state.carts = [].concat(state.carts, carts);
            sessionStorage.setItem("carts", JSON.stringify(state.carts));
        },
        pickup(state, _carts) {
            state.pickup = _carts;
            sessionStorage.setItem("pickup", JSON.stringify(_carts));
        },
        pickup__target(state, _carts) {
            state.pickup__target = _carts;
            sessionStorage.setItem("pickup__target", JSON.stringify(_carts));
        },
        setWatches(state, { watches }) {
            state.watches = watches;
            sessionStorage.setItem("watches", JSON.stringify(state.watches));
        },
        addToWatch(state, watches) {
            for (const watch of watches) {
                const index = state.watches.findIndex(({ _id }) => _id == watch._id);
                if (-1 < index) state.watches[index] = watch;
                else state.watches = [watch, ...state.watches];
                [].sort;
                state.watches = state.watches.sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 0));
            }
            sessionStorage.setItem("watches", JSON.stringify(state.watches));
        },
        setIslands(state, { islands }) {
            state.islands = islands;
        },
        setCategories(state, { categories }) {
            state.categories = categories;
        },
        setShipping__everyAsset(state, { shipping }) {
            state.shipping__everyAsset = shipping;
        },

        //join
        agreements(state, agreements) {
            state.agreements = agreements;
            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },

        // ui
        hideMainAlert(state) {
            state.main.alert = false;
        },
    },
    actions: {
        // user
        async login({ dispatch, commit, state }, { username, password, accessToken }) {
            if (accessToken) commit("login", { accessToken });
            else {
                const { refreshToken } = await api.auth.getRefreshToken({ username, password });
                const { accessToken } = await api.auth.getAccessToken(refreshToken);
                commit("login", { refreshToken, accessToken });
            }

            await Promise.all(state.carts.map(async (cart) => await api.v1.me.carts.put(cart)));
            await Promise.all(state.watches.map(async (cart) => await api.v1.me.watches.put(cart)));

            dispatch("getCarts");
            dispatch("getWatches");
        },
        async refresh({ commit }, refreshToken) {
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },
        logout({ commit }) {
            commit("logout");
        },
        async getUser({ commit }) {
            const { user } = await api.v1.me.get();
            commit("setUser", { user });
        },
        agreements({ commit }, _terms) {
            commit("agreements", _terms);
        },

        // common
        async getSetting__v1({ commit }) {
            const { setting } = await api.v1.setting.get();
            commit("setSetting", { setting });
        },
        async getSetting__console({ commit }) {
            const { setting } = await api.console.setting.get();
            commit("setSetting", { setting });
        },
        async getLikes({ commit }) {
            const { likes } = await api.v1.me.likes.gets();
            commit("setLikes", { likes });
        },

        // shop
        addToCart({ commit }, carts) {
            commit("addToCart", carts);
        },
        pickup({ commit }, _carts) {
            commit("pickup", _carts);
        },
        pickup__target({ commit }, _carts) {
            commit("pickup__target", _carts);
        },
        addToWatch({ commit }, watches) {
            commit("addToWatch", watches);
        },
        async getCarts({ commit }) {
            const { carts } = await api.v1.me.carts.gets();
            commit("setCarts", { carts });
        },
        async getWatches({ commit }) {
            const { watches } = await api.v1.me.watches.gets();
            commit("setWatches", { watches });
        },
        async getIslands({ commit }) {
            const { islands } = await api.v1.shop.islands.gets();
            commit("setIslands", { islands });
        },
        async getCategories({ commit }) {
            const { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            commit("setCategories", { categories });
        },
        async getShipping__everyAsset({ commit }) {
            const { shipping } = await api.v1.shop.shippings.gets({
                headers: { mode: "item" },
                params: { targetType: SHIPPING_TARGET_TYPES.EVERY_ASSET.value },
            });

            commit("setShipping__everyAsset", { shipping });
        },
    },
    getters: {
        displayCodes(state) {
            const items = state.setting.shop.displayCodes.map(initSettingShopDisplayCode);

            items.push(...[...Object.values(DISPLAY_CODES).filter(({ value }) => !items.some((item) => item.value == value))].map(initSettingShopDisplayCode));

            return items;
        },

        productLabels(state) {
            return state.setting?.shop?.productLabels || [];
        },

        mapProductLabels(state, getters) {
            return (labels = []) => labels.map((value) => getters.productLabels.find((item) => item.value == value));
        },

        shippingCodes(state) {
            const items = state.setting.shop.shippingCodes.map(initSettingShopShippingCode);

            items.push(...[...Object.values(SHIPPING_CODES).filter(({ value }) => !items.some((item) => item.value == value))].map(initSettingShopShippingCode));

            return items;
        },

        getShippingCodeText(state, getters) {
            return (code) => (getters.shippingCodes || []).find(({ value }) => value == code)?.text || code;
        },

        userLevel(state) {
            return state.user?.level;
        },

        userLevelDiscount(state, getters) {
            return getters.userLevel?.discounts?.find?.((item) => item.isActive && item.type == USER_LEVEL_DISCOUNT_TYPES.PRODUCT_PURCHASE_AMOUNT.value);
        },

        getDiscountPrice(state, getters) {
            const { userLevelDiscount } = getters;

            return (product) => {
                switch (userLevelDiscount?.unit) {
                    case USER_LEVEL_DISCOUNT_UNITS.AMOUNT.value: {
                        return (product?.discountPrice || 0) + (userLevelDiscount?.value || 0);
                    }
                    case USER_LEVEL_DISCOUNT_UNITS.PERCENT.value: {
                        let price = Math.floor(((product?.salePrice || 0) * (userLevelDiscount?.value || 0)) / 100);
                        return (product?.discountPrice || 0) + price;
                    }
                    default: {
                        return product?.discountPrice || 0;
                    }
                }
            };
        },

        getDiscountRate(state, getters) {
            const { userLevelDiscount } = getters;
            return (product) => {
                switch (userLevelDiscount?.unit) {
                    case USER_LEVEL_DISCOUNT_UNITS.AMOUNT.value:
                    case USER_LEVEL_DISCOUNT_UNITS.PERCENT.value: {
                        return Math.ceil((getters.getDiscountPrice(product) / product.price) * 100);
                    }
                    default: {
                        return product?.discountRate || 0;
                    }
                }
            };
        },

        getSalePrice(state, getters) {
            const { userLevelDiscount } = getters;
            return (product) => {
                switch (userLevelDiscount?.unit) {
                    case USER_LEVEL_DISCOUNT_UNITS.AMOUNT.value:
                    case USER_LEVEL_DISCOUNT_UNITS.PERCENT.value: {
                        return (product?.price || 0) - getters.getDiscountPrice(product);
                    }
                    default: {
                        return product?.salePrice || 0;
                    }
                }
            };
        },
    },
    modules: {
        bandBanners,
        enlipleTracker,
        naverWcs,
        metaPixel,
        googleTag
    },
});
