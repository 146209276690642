<template>
    <div>
        <main-aside-banner />
        <main-aside-login />
        <main-aside-me />
        <main-aside-purchases />
        <main-aside-carts />
        <main-aside-likes />
        <main-aside-views />
        <main-aside-services />
        <main-aside-installation />
        <main-aside-go-to-top />
    </div>
</template>

<script>
import MainAsideMe from "./main-aside-me.vue";
import MainAsideLogin from "./main-aside-login.vue";
import MainAsideCarts from "./main-aside-carts.vue";
import MainAsideLikes from "./main-aside-likes.vue";
import MainAsideViews from "./main-aside-views.vue";
import MainAsideBanner from "./main-aside-banner.vue";
import MainAsideGoToTop from './main-aside-go-to-top.vue';
import MainAsideServices from "./main-aside-services.vue";
import MainAsidePurchases from "./main-aside-purchases.vue";
import MainAsideInstallation from "./main-aside-installation.vue";

export default {
    components: {
        MainAsideMe,
        MainAsideLogin,
        MainAsideCarts,
        MainAsideLikes,
        MainAsideViews,
        MainAsideBanner,
        MainAsideGoToTop,
        MainAsideServices,
        MainAsidePurchases,
        MainAsideInstallation,
    },
};
</script>

<style></style>
