var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.accessToken ? _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "위시리스트"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-underline",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey",
      "to": "/mypage/likes"
    }
  }, [_vm._v("더 보기")])], 1), _vm.items.length ? [_c('div', {
    staticClass: "side-slide"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.items, function (item) {
    var _item$product, _item$product$thumb;
    return [_c('swiper-slide', {
      key: item._id
    }, [_c('v-card', {
      attrs: {
        "to": `/shop/products/${item._product}`
      }
    }, [_c('div', {
      staticClass: "side-slide__img",
      style: `background-image: url(${item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url})`
    })])], 1)];
  }), _c('div', {
    staticClass: "swiper-pagination swiper-pagination--dark",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })], 2)], 1)] : [_c('v-col', {
    staticClass: "text-center"
  }, [_vm._v(" 담은 상품이 없습니다 ")])]], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }