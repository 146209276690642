<template>
    <page-section>
        <v-row>
            <v-col cols="12" lg="auto" :style="$vuetify.breakpoint.lgAndUp ? 'flex: 0 0 77.7778%; max-width:77.7778%;' : ''">
                <main-visual-tabs />
            </v-col>
            <v-col cols="12" lg="auto" :style="$vuetify.breakpoint.lgAndUp ? 'flex: 0 0 22.2222%; max-width:22.2222%;' : ''">
                <!-- <main-visual-aside :code="BANNERS.MAIN_2.code__pc" isPc /> -->
                <main-visual-aside :code="BANNERS.MAIN_2.code__pc" class="d-none d-lg-block" />
                <!-- <main-visual-aside :code="BANNERS.MAIN_2.code__mb" isMb /> -->
                <main-visual-aside :code="BANNERS.MAIN_2.code__mb" class="d-lg-none" />
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { BANNERS } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import MainVisualTabs from "@/components/client/main/main-visual/tabs/main-visual-tabs.vue";
import MainVisualAside from "./main-visual-aside.vue";

export default {
    components: {
        PageSection,
        MainVisualTabs,
        MainVisualAside,
    },
    data: () => ({
        BANNERS,
    }),
};
</script>
