var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expand-transition', [_c('v-alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shows,
      expression: "shows"
    }],
    staticClass: "top-banner",
    attrs: {
      "tile": "",
      "width": "100%",
      "dismissible": ""
    },
    scopedSlots: _vm._u([{
      key: "close",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "top-banner__close",
          attrs: {
            "absolute": "",
            "right": "",
            "center": "",
            "fab": ""
          },
          on: {
            "click": function ($event) {
              return _vm.hideBanner(_vm.item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "24",
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-close")])], 1)];
      },
      proxy: true
    }])
  }, [_c(_vm.linkAttrs.is, _vm._b({
    tag: "component",
    staticClass: "top-banner__link"
  }, 'component', _vm.linkAttrs, false), [_c('v-img', _vm._b({
    staticClass: "top-banner__img top-banner__img-mobile",
    attrs: {
      "contain": "",
      "width": "100%",
      "height": "100%",
      "max-height": _vm.$vuetify.breakpoint.lgAndUp ? 'none' : 300
    }
  }, 'v-img', {
    src: _vm.src
  }, false)), _c('div', _vm._b({
    staticClass: "top-banner__img top-banner__img-pc",
    style: 'background-image:url(' + _vm.src + ')'
  }, 'div', {
    src: _vm.src
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }