var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "sns-button",
    attrs: {
      "icon": "",
      "href": "https://www.instagram.com/joeyforlifemall/",
      "target": "_blank"
    }
  }, [_c('v-img', {
    attrs: {
      "width": "46px",
      "src": "/images/icon/icon-instagram-colored.svg"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }