var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": _vm.to
    }
  }, [_vm._t("default", null, {
    "carts": _vm.carts
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }