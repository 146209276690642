export * from "./board.constants";
export * from "./center-questions.constants";
export * from "./common.issue.constants";
export * from "./common.usage.constants";
export * from "./forms.constants";
export * from "./shop-bulk-upload.constants";
export * from "./shop-coupons.constants";
export * from "./shop-points.constants";
export * from "./shop-products.constants";
export * from "./shop-shippings.constants";
export * from "./user-levels.constants";
export * from "./user-points.constants";

export const USER_TYPES = {
    PERSON: { value: "PERSON", text: "개인회원" },
    COMPANY: { value: "COMPANY", text: "기업회원" },
    OPERATOR: { value: "OPERATOR", text: "관리자" },
};

export const USER_LEVEL_CODES = {
    ROOT: { value: "ROOT", text: "최고관리자", shows: false },
    ADMIN: { value: "ADMIN", text: "관리자", shows: false },
    PERSON: { value: "PERSON", text: "개인회원", shows: true },
    COMPANY: { value: "COMPANY", text: "기업회원", shows: true },
};

export const USER_LEVEL_SCHEDULE_ITEMS = {
    price: [
        { text: "구매없음", value: 0 },
        { text: "5만원이상", value: 50000 },
        { text: "10만원이상", value: 100000 },
        { text: "20만원이상", value: 200000 },
        { text: "30만원이상", value: 300000 },
        { text: "50만원이상", value: 500000 },
        { text: "100만원이상", value: 1000000 },
    ],
    count: [
        { text: "구매없음", value: 0 },
        { text: "구매1회이상", value: 1 },
        { text: "구매2회이상", value: 2 },
        { text: "구매3회이상", value: 3 },
        { text: "구매5회이상", value: 5 },
        { text: "구매10회이상", value: 10 },
        { text: "구매20회이상", value: 20 },
        { text: "구매30회이상", value: 30 },
        { text: "구매50회이상", value: 50 },
    ],
};

export const SHOP_CODE_TYPES = {
    PROMOTER: { value: "PROMOTER", text: "추천인코드" },
};

export const INQUIRY_TYPES = {
    PROMOTER_APPROVAL: { value: "PROMOTER_APPROVAL", text: "정회원 전환요청" },
};

export const INQUIRY_STATES = {
    AWAITING: { value: "AWAITING", text: "대기" },
    APPROVED: { value: "APPROVED", text: "승인" },
    REJECTED: { value: "REJECTED", text: "반려" },
};

export const ORDER_STATUSES = {
    PURCHASE_INITIATED: { value: "PURCHASE_INITIATED", text: "주문생성완료" },
    PURCHASE_GENERATED: { value: "PURCHASE_GENERATED", text: "구매생성완료" },
    PURCHASE_CONFIRMED: { value: "PURCHASE_CONFIRMED", text: "구매확정완료" },
};

export const PURCHASE_ORDER_STATUSES = {
    ORDER_NONPAYMENT: { value: "ORDER_NONPAYMENT", text: "미결제" },
    ORDER_CANCELED: { value: "ORDER_CANCELED", text: "주문취소" },
    ORDER_PAYMENT: { value: "ORDER_PAYMENT", text: "결제완료" },
    ORDER_CONFIRM: { value: "ORDER_CONFIRM", text: "배송준비중" },
    SHIPPING_CONFIRM: { value: "SHIPPING_CONFIRM", text: "배송중" },
    SHIPPING_DELAYED: { value: "SHIPPING_DELAYED", text: "배송지연" },
    DELIVERY_COMPLETE: { value: "DELIVERY_COMPLETE", text: "배송완료" },
};

export const PURCHASE_CLAIM_STATUSES = {
    CANCEL_REQUESTED: { value: "CANCEL_REQUESTED", text: "취소요청" },
    CANCEL_COMPLETED: { value: "CANCEL_COMPLETED", text: "취소완료" },
    CANCEL_REJECTED: { value: "CANCEL_REJECTED", text: "취소거부" },
    EXCHANGE_REQUESTED: { value: "EXCHANGE_REQUESTED", text: "교환요청" },
    EXCHANGE_COMPLETED: { value: "EXCHANGE_COMPLETED", text: "교환완료" },
    EXCHANGE_REJECTED: { value: "EXCHANGE_REJECTED", text: "교환거부" },
    EXCHANGE_PENDING: { value: "EXCHANGE_PENDING", text: "교환보류" },
    EXCHANGE_DELAYED: { value: "EXCHANGE_DELAYED", text: "교환지연" },
    EXCHANGE_SHIPPING: { value: "EXCHANGE_SHIPPING", text: "교환재배송" },
    RETURN_REQUESTED: { value: "RETURN_REQUESTED", text: "반품요청" },
    RETURN_COMPLETED: { value: "RETURN_COMPLETED", text: "반품완료" },
    RETURN_REJECTED: { value: "RETURN_REJECTED", text: "반품거부" },
};

export const PURCHASE_PURCHASE_STATUSES = {
    PURCHASE_PENDING: { value: "PURCHASE_PENDING", text: "구매확정보류" },
    PURCHASE_AWAITING: { value: "PURCHASE_AWAITING", text: "구매확정대기" },
    PURCHASE_SCHEDULE: { value: "PURCHASE_SCHEDULE", text: "구매확정예정" },
    PURCHASE_COMPLETE: { value: "PURCHASE_COMPLETE", text: "구매확정완료" },
    PURCHASE_CANCELED: { value: "PURCHASE_CANCELED", text: "구매취소완료" },
};

export const PURCHASE_COLLECTION_STATUSES = {
    COLLECTION_PENDING: { value: "COLLECTION_PENDING", text: "수거보류" },
    COLLECTION_PROGRESS: { value: "COLLECTION_PROGRESS", text: "수거중" },
    COLLECTION_COMPLETE: { value: "COLLECTION_COMPLETE", text: "수거완료" },
};

export const PURCHASE_QUERY_CODES = {
    paymentProcessing: {
        text: "결제대기",
        value: "paymentProcessing",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.ORDER_NONPAYMENT.value] } },
    },
    paymentCrossedOut: {
        text: "결제확인",
        value: "paymentCrossedOut",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.ORDER_PAYMENT.value] } },
    },
    orderAcknowledged: {
        text: "상품준비중",
        value: "orderAcknowledged",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.ORDER_CONFIRM.value] } },
    },
    shippingConfirmed: {
        text: "배송중",
        value: "shippingConfirmed",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.SHIPPING_CONFIRM.value] } },
    },
    deliveryCompleted: {
        text: "배송완료",
        value: "deliveryCompleted",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.DELIVERY_COMPLETE.value] } },
    },
    purchaseCompleted: {
        text: "구매확정",
        value: "purchaseCompleted",
        params: { purchaseStatus: { $in: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value] } },
    },
    claimProcessGoing: {
        text: "취소·교환·반품",
        value: "claimProcessGoing",
        params: {
            claimStatus: { $in: [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value, PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value, PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value, PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value, PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value, PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value] },
            purchaseStatus: { $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value] },
        },
    },
};

export const GRADE_CRITERIA_TYPES = {
    SCORE__ACCUMULATED: { value: "SCORE__ACCUMULATED", text: "직급포인트 (합산/누적)" },
    PURCHASE_AMOUNT__MONTH: { value: "PURCHASE_AMOUNT__MONTH", text: "구매확정금액 (회원본인)" },
};

export const GRADE_BENEFITS_TYPES = {
    BONUS__GOODS: { value: "BONUS__GOODS", text: "기본 보너스" },
    BONUS__GRADE: { value: "BONUS__GRADE", text: "직급 보너스" },
    BONUS__SALES: { value: "BONUS__SALES", text: "장려 보너스" },
};

export const SCORE_TYPES = {
    PROMOTEE__MANUAL_ALTERATION: { value: "PROMOTEE__MANUAL_ALTERATION", text: "관리자 수정 (추천회원)" },
    PROMOTEE__PURCHASE_COMPLETE: { value: "PROMOTEE__PURCHASE_COMPLETE", text: "추천회원 구매확정" },
    PROMOTER__MANUAL_ALTERATION: { value: "PROMOTER__MANUAL_ALTERATION", text: "관리자 수정 (회원본인)" },
    PROMOTER__PURCHASE_COMPLETE: { value: "PROMOTER__PURCHASE_COMPLETE", text: "회원본인 구매확정" },
    PROMOTER__REVIEW_WRITE_TEXT: { value: "PROMOTER__REVIEW_WRITE_TEXT", text: "회원본인 일반리뷰" },
    PROMOTER__REVIEW_UPLOAD_IMG: { value: "PROMOTER__REVIEW_UPLOAD_IMG", text: "회원본인 포토리뷰" },
};

export const BONUS_TYPES = {
    BONUS__ADMIN: { value: "BONUS__ADMIN", text: "보너스 수정" },
    BONUS__GOODS: { value: "BONUS__GOODS", text: "기본 보너스" },
    BONUS__GRADE: { value: "BONUS__GRADE", text: "직급 보너스" },
    BONUS__SALES: { value: "BONUS__SALES", text: "장려 보너스" },
    BONUS__DEBIT: { value: "BONUS__DEBIT", text: "출금내역" },
};

export const BONUS_TABS = {
    WHOLE: { value: "WHOLE", text: "전체", path: "bonuses" },
    GOODS: { value: "GOODS", text: "기본 보너스", path: "bonuses__goods" },
    GRADE: { value: "GRADE", text: "직급 보너스", path: "bonuses__grade" },
    SALES: { value: "SALES", text: "장려 보너스", path: "bonuses__sales" },
    DEBIT: { value: "DEBIT", text: "출금내역", path: "bonuses__debit" },
};

export const BONUS_WITHDRAW_TABS = {
    CONFIRM_BONUS_AMOUNT: { value: "CONFIRM_BONUS_AMOUNT", text: "금액확인" },
    CONFIRM_BANK_ACCOUNT: { value: "CONFIRM_BANK_ACCOUNT", text: "계좌확인" },
    VERIFY_USER_IDENTITY: { value: "VERIFY_USER_IDENTITY", text: "신원확인" },
    CONFIRM_DEPOSIT_DONE: { value: "CONFIRM_DEPOSIT_DONE", text: "결과확인" },
};

export const BANK_VERIFICATION_FORM_TABS = {
    INPUT: { value: "INPUT", text: "입력" },
    VERIFY: { value: "VERIFY", text: "인증" },
    SUCCESS: { value: "SUCCESS", text: "성공" },
};

export const EXHIBITION_GROUPS = {
    BARGAIN_SALES: { value: "BARGAIN_SALES", text: "특가" },
    PLANNED_SALES: { value: "PLANNED_SALES", text: "기획전" },
    POP_UP_EVENTS: { value: "POP_UP_EVENTS", text: "이벤트" },
};

export const CONCIERGE_SERVICE_CATEGORIES = {
    PRODUCT_QUERY: { value: "PRODUCT_QUERY", text: "제품상담" },
    AFTER_SERVICE: { value: "AFTER_SERVICE", text: "A/S문의" },
    SHOWROOM_BOOK: { value: "SHOWROOM_BOOK", text: "쇼룸방문예약" },
    INSTALLATIONS: { value: "INSTALLATIONS", text: "설치문의" },
};

export const FAQ_TYPES = {
    MEMBERSHIP: { value: "MEMBERSHIP", text: "회원문의" },
    PURCHASING: { value: "PURCHASING", text: "주문결제" },
    ORDERCLAIM: { value: "ORDERCLAIM", text: "취소·교환·반품" },
    DELIVERIES: { value: "DELIVERIES", text: "배송문의" },
    OTHERQUERY: { value: "OTHERQUERY", text: "A/S·기타문의" },
};

export const BANNERS = {
    MAIN_1: {
        value: "MAIN_1",
        title: "섹션 1 - 슬라이드 1",
        code__pc: "MAIN_1__PC",
        code__mb: "MAIN_1__MB",
        sublabel__pc: "",
        sublabel__mb: "",
    },
    MAIN_6: {
        value: "MAIN_6",
        title: "섹션 1 - 케어제품",
        code__pc: "MAIN_6__PC",
        code__mb: "MAIN_6__MB",
        sublabel__pc: "",
        sublabel__mb: "",
    },
    MAIN_2: {
        value: "MAIN_2",
        title: "섹션 1 - 슬라이드 2",
        code__pc: "MAIN_2__PC",
        code__mb: "MAIN_2__MB",
        sublabel__pc: "",
        sublabel__mb: "",
    },
    MAIN_3: {
        value: "MAIN_3",
        title: "섹션 3 - 슬라이드",
        code__pc: "MAIN_3__PC",
        code__mb: "MAIN_3__MB",
        sublabel__pc: "",
        sublabel__mb: "",
    },
    MAIN_4: {
        value: "MAIN_4",
        title: "섹션 5 - 슬라이드",
        code__pc: "MAIN_4__PC",
        code__mb: "MAIN_4__MB",
        sublabel__pc: "",
        sublabel__mb: "",
    },
    MAIN_5: {
        value: "MAIN_5",
        title: "사이드 - 슬라이드",
        code__pc: "MAIN_5__PC",
        code__mb: "MAIN_5__MB",
        sublabel__pc: "",
        sublabel__mb: "",
        isSingle: true,
    },
};

export const FORM_STATES = {
    ANSWER_AWAITING: { value: "ANSWER_AWAITING", text: "답변대기" },
    ANSWER_COMPLETE: { value: "ANSWER_COMPLETE", text: "답변완료" },
};

export const DISPLAY_CODES = {
    NEW_ARRIVAL: { value: "NEW_ARRIVAL", text: "신상품" },
    BEST_SELLER: { value: "BEST_SELLER", text: "베스트 셀러" },
    POPULAR: { value: "POPULAR", text: "이달의 인기상품" },
    BEST_REVIEW: { value: "BEST_REVIEW", text: "베스트 리뷰", shows: false },
};
