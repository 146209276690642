import Vue from "vue";
import Vuetify from "vuetify";
import { ko } from "vuetify/src/locale";
import VCurrencyField from "v-currency-field";

// v-currency-field
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                base: "#006DD1",
                lighten5: "#F6FCEB",
                lighten4: "#D9F1A9",
                lighten3: "#C4E979",
                lighten2: "#A8DF3B",
                lighten1: "#9CD823",
                darken1: "#76A41B",
                darken2: "#638916",
                darken3: "#506F12",
                darken4: "#3D550E",
            },
            secondary: "#9B6241",
            anchor: "inherit",
            grey: {
                base: "#666",
                lighten5: "#f7f7f7",
                lighten4: "#ececec",
                lighten3: "#ddd",
                lighten2: "#aaa",
                lighten1: "#999",
                darken1: "#555",
                darken2: "#444",
                darken3: "#333",
                darken4: "#111",
            },
            red: {
                base: "#FF0000",
                lighten5: "#FDF3F3",
                darken3: "#CC0F17",
            },
            "light-blue": {
                base: "#3CB4E5",
                lighten5: "#E2F4FB",
            },
            purple: {
                base: "#9D5EDC",
                lighten5: "#FBF9FE",
            },
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00a4e0",
            secondary: "#445163",
            accent: "#00a4e0",
            // error: '#FF5252',
            info: "#2D3539",
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: "#333",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
