/** @type {import("vuex").Module} */
export const googleTag = {
    namespaced: true,
    actions: {
        googleTagPurchase(store, /**@type {GoogleTagPurchaseProps} */ props) {
            if (window.gtag) gtag("event", "purchase", props);
        },
    },
};

/**
 * @typedef GoogleTagPurchaseProps
 * @property {string} currency 통화 / Required
 * @property {number} value items의 총 구매금액 (shipping, tax 제외) / Required
 * @property {string} transaction_id 거래코드 / Required
 * @property {string} coupon 전체 쿠폰
 * @property {number} shipping 배송료
 * @property {number} tax 세금
 * @property {GoogleTagItem[]} items 구매상품 리스트 / Required
 */

/**
 * @typedef GoogleTagItem
 * @property {string} item_id 상품 id / Required
 * @property {string} item_name 상품명 / Required
 * @property {string} affiliation 구매처
 * @property {string} coupon 상품 쿠폰
 * @property {number} discount 할인액
 * @property {number} index 상품 순서
 * @property {string} item_brand 상품 브랜드
 * @property {string} item_category 상위 카테고리
 * @property {string} item_category2 하위 카테고리
 * @property {string} item_category3 하위 카테고리
 * @property {string} item_category4 하위 카테고리
 * @property {string} item_category5 하위 카테고리
 * @property {string} item_list_id 싱픔 리스트 id
 * @property {string} item_list_name 상품 리스트명
 * @property {string} item_variant 상품 옵션명
 * @property {string} location_id 상품 위치 (판매처 위치)
 * @property {string} price 가격 (할인 포함)
 * @property {string} quantity 수량
 */
