<template>
    <v-responsive :aspect-ratio="1030 / 446">
        <v-card outlined class="h-100 rounded-b-0 overflow-hidden">
            <v-row no-gutters class="h-100">
                <v-col cols="12" lg="auto" order-lg="2">
                    <div class="w-lg-230px h-100 grey lighten-5">
                        <div class="d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px">
                            <v-btn icon class="transparent" style="position: absolute; top: 0; right: 0" @click="$emit('close')"><v-icon size="20" color="grey darken-4">mdi-close</v-icon></v-btn>
                            <h2 class="tit tit--xs">컨시어지 서비스</h2>

                            <!-- S: 설치문의 -->
                            <main-visual-tab-signature-form />
                            <!-- E: 설치문의 -->
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" lg="" order-lg="1">
                    <v-row no-gutters class="h-100">
                        <v-col cols="12" md="6">
                            <div class="d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px">
                                <div class="tit-wrap tit-wrap--sm">
                                    <h2 class="tit tit--xs">판교 오프라인 쇼룸</h2>
                                </div>
                                <v-row align-content="space-between">
                                    <v-col cols="12">
                                        <v-card class="main-signature-slide overflow-hidden">
                                            <swiper class="swiper" :options="swiperOptionsSignatureSlide">
                                                <swiper-slide>
                                                    <v-card to="/" class="rounded-b-0 overflow-hidden">
                                                        <div class="main-signature-slide__img" style="background-image: url(/images/main/main-signature-img.jpg)"></div>
                                                    </v-card>
                                                </swiper-slide>
                                                <swiper-slide>
                                                    <v-card to="/" class="rounded-b-0 overflow-hidden">
                                                        <div class="main-signature-slide__img" style="background-image: url(/images/main/main-signature-img.jpg)"></div>
                                                    </v-card>
                                                </swiper-slide>
                                                <div class="swiper-control" slot="pagination">
                                                    <div class="swiper-pagination"></div>
                                                </div>
                                            </swiper>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row align="center" class="row--sm flex-nowrap">
                                            <v-col cols="">
                                                <p class="font-size-14 txt--dark">판교 쇼룸에서 다양한 제품을 실제로 체험하고 구매하세요.</p>
                                            </v-col>
                                            <v-col cols="auto" class="text-right">
                                                <v-btn v-bind="{ ...btn_secondary, ...$attrs }" target="_blank" href="https://map.naver.com/p/search/%EC%A1%B0%EC%9D%B4%ED%8F%AC%EB%9D%BC%EC%9D%B4%ED%94%84/place/1654966042?c=15.00,0,0,0,dh" class="px-lg-10px">오시는 길</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="mx-n1px">
                            <v-divider vertical class="align-self-stretch" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px">
                                <div class="tit-wrap tit-wrap--sm">
                                    <h2 class="tit tit--xs">온라인 쇼룸</h2>
                                </div>
                                <v-row align-content="space-between">
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-card>
                                                    <v-img :aspect-ratio="148 / 258" src="/images/main/main-signature-img2.jpg" />
                                                </v-card>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-card>
                                                    <v-img :aspect-ratio="148 / 258" src="/images/main/main-signature-img3.jpg" />
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row align="center" class="row--sm flex-nowrap">
                                            <v-col cols="">
                                                <p class="font-size-14 txt--dark">온라인 쇼룸에서 제품을 확인해 보세요.</p>
                                            </v-col>
                                            <v-col cols="auto" class="text-right">
                                                <v-btn v-bind="{ ...btn_secondary, ...$attrs }" to="/shop/showrooms" class="px-lg-10px">자세히</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-responsive>
</template>

<script>
import { btn_secondary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import MainVisualTabSignatureForm from "./main-visual-tab-signature-form.vue";

export default {
    props: {},
    components: {
        PageSection,
        MainVisualTabSignatureForm,
    },
    data: () => {
        return {
            btn_secondary,

            swiperOptionsSignatureSlide: {
                allowTouchMove: true,
                centeredSlides: false,
                spaceBetween: 12,
                autoplay: {
                    delay: 5000,
                    waitForTransition: true,
                    disableOnInteraction: false,
                },
                speed: 500,
                lazy: {
                    loadPrevNext: true,
                },
                loop: true,
                pagination: {
                    el: ".main-signature-slide .swiper-pagination",
                    clickable: true,
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.main-visual-tabs {
    .row--sm {
        --grid-gutter-sm: 4px;
    }
}
::v-deep {
    .v-label {
        font-size: 14px !important;
    }
}

.main-signature-slide {
    .swiper-slide {
        width: 100% !important;
    }
    .swiper-control {
        bottom: 30px;
    }
    .swiper-pagination {
        display: flex;
        justify-content: center;
    }
    &__img {
        width: 100%;
        padding-top: calc(258 / 318 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: var(--card-border-radius) !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
