var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1030 / 446
    }
  }, [_c('v-card', {
    staticClass: "h-100 rounded-b-0 overflow-hidden",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto",
      "order-lg": "2"
    }
  }, [_c('div', {
    staticClass: "w-lg-230px h-100 grey lighten-5"
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px"
  }, [_c('v-btn', {
    staticClass: "transparent",
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "right": "0"
    },
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20",
      "color": "grey darken-4"
    }
  }, [_vm._v("mdi-close")])], 1), _c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("컨시어지 서비스")]), _c('main-visual-tab-signature-form')], 1)])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "",
      "order-lg": "1"
    }
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("판교 오프라인 쇼룸")])]), _c('v-row', {
    attrs: {
      "align-content": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "main-signature-slide overflow-hidden"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptionsSignatureSlide
    }
  }, [_c('swiper-slide', [_c('v-card', {
    staticClass: "rounded-b-0 overflow-hidden",
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "main-signature-slide__img",
    staticStyle: {
      "background-image": "url(/images/main/main-signature-img.jpg)"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    staticClass: "rounded-b-0 overflow-hidden",
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "main-signature-slide__img",
    staticStyle: {
      "background-image": "url(/images/main/main-signature-img.jpg)"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination"
  })])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm flex-nowrap",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('p', {
    staticClass: "font-size-14 txt--dark"
  }, [_vm._v("판교 쇼룸에서 다양한 제품을 실제로 체험하고 구매하세요.")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "px-lg-10px",
    attrs: {
      "target": "_blank",
      "href": "https://map.naver.com/p/search/%EC%A1%B0%EC%9D%B4%ED%8F%AC%EB%9D%BC%EC%9D%B4%ED%94%84/place/1654966042?c=15.00,0,0,0,dh"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, _vm.$attrs), false), [_vm._v("오시는 길")])], 1)], 1)], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "mx-n1px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "align-self-stretch",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("온라인 쇼룸")])]), _c('v-row', {
    attrs: {
      "align-content": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "aspect-ratio": 148 / 258,
      "src": "/images/main/main-signature-img2.jpg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "aspect-ratio": 148 / 258,
      "src": "/images/main/main-signature-img3.jpg"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm flex-nowrap",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('p', {
    staticClass: "font-size-14 txt--dark"
  }, [_vm._v("온라인 쇼룸에서 제품을 확인해 보세요.")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "px-lg-10px",
    attrs: {
      "to": "/shop/showrooms"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, _vm.$attrs), false), [_vm._v("자세히")])], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }