var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-head"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "divider-group divider-group--large"
  }, [_c('ul', {
    staticClass: "white--text"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/about"
    }
  }, [_vm._v("회사소개")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/mypage/questions"
    }
  }, [_vm._v("1:1문의")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/center/faq"
    }
  }, [_vm._v("자주묻는 질문")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("개인정보처리방침 ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/terms"
    }
  }, [_vm._v("이용약관")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/form/inquiry-partner"
    }
  }, [_vm._v("제휴문의")])], 1)])])]), _c('v-col', {
    staticClass: "ml-auto",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('footer-family-site')], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "footer-body"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "inline-group"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "font-size-14 font-weight-medium white--text mb-12px"
  }, [_vm._v("조이포라이프 공식몰")])]), _c('li', {
    staticClass: "clear"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("상호명 ")]), _vm._v("조이포라이프 주식회사")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("대표이사 ")]), _vm._v("하기호")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("주소 ")]), _vm._v("경기도 성남시 대왕판교로 187 / 경기도 화성시 장안면 장안로 480-192")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("전자우편주소 ")]), _vm._v("jflmall@joeyforlife.com")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("팩스 ")]), _vm._v("070-5038-4807")]), _c('li', {
    staticClass: "clear"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("사업자등록번호 ")]), _vm._v("120-86-43097")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("통신판매업신고 ")]), _vm._v("제2016-화성장안-01호")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("개인정보책임관리자 ")]), _vm._v("하기호")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("CS직통번호 ")]), _vm._v("070-4010-5061")]), _c('li', {
    staticClass: "py-md-0"
  }, [_c('span', {
    staticClass: "footer-account"
  }, [_vm._v("무통장입금 기업은행 960-004190-04-040")])])])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "footer-copyright"
  }, [_c('p', [_vm._v("당사의 모든 제작물의 저작권은 조이포라이프 몰에 있으며, 무단복제나 도용은 저작권법(97조5항)에 의해 금지되어 있습니다. 이를 위반 시 법적인 처벌을 받을 수 있습니다.")]), _c('p', [_vm._v("Copyright 2023. JOEYFORLIFE CO.,Ltd. All Rights Reserved.")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mt-10px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "cursor-pointer",
          attrs: {
            "width": "60",
            "aspect-ratio": 150 / 175,
            "src": "/images/nicepay-logo.png"
          }
        }, 'v-img', {
          attrs,
          on
        }, false), on))];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/nicepay-img.png"
    }
  })], 1)], 1)], 1)], 1)])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }