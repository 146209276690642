var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm._t("bandBanner"), _c('main-header', _vm._b({}, 'main-header', {
    disableScrollTop: _vm.disableScrollTop
  }, false)), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_vm._t("subHead"), _vm._t("contentsImmersive"), _c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('div', {
    staticClass: "fixed-contents",
    attrs: {
      "data-v-sticky-container": ""
    }
  }, [_c('v-row', {
    staticClass: "row--contents flex-nowrap",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "flex-shrink-1",
    class: _vm.contentsColClass,
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    class: _vm.contentsClass
  }, [_vm._t("default")], 2)]), _c('v-col', {
    staticClass: "align-self-stretch d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-100 mr-n1px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-none d-xl-block py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    directives: [{
      name: "sticky",
      rawName: "v-sticky",
      value: _vm.stickyOptions,
      expression: "stickyOptions"
    }],
    staticClass: "w-290px"
  }, [!_vm.hasCustomAside ? _c('main-aside') : _vm._e(), _vm._t("custom-aside")], 2)])], 1)], 1)])], 1)], 2), _c('main-footer'), _c('sns-button')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }