var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-row', [_c('v-col', {
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'flex: 0 0 77.7778%; max-width:77.7778%;' : '',
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('main-visual-tabs')], 1), _c('v-col', {
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'flex: 0 0 22.2222%; max-width:22.2222%;' : '',
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('main-visual-aside', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "code": _vm.BANNERS.MAIN_2.code__pc
    }
  }), _c('main-visual-aside', {
    staticClass: "d-lg-none",
    attrs: {
      "code": _vm.BANNERS.MAIN_2.code__mb
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }