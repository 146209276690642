var render = function render(){
  var _vm$product, _vm$product$thumb, _vm$product2, _vm$product2$flags, _vm$product3, _vm$product4, _vm$product4$meta, _vm$product4$meta$rev;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-16px"
  }, [_c('router-link', {
    attrs: {
      "to": `/shop/products/${_vm.product._id}`
    }
  }, [_c('v-row', {
    staticClass: "flex-nowrap",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticStyle: {
      "flex": "0 0 114px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "square-img",
    style: `background-image: url(${(_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$thumb = _vm$product.thumb) === null || _vm$product$thumb === void 0 ? void 0 : _vm$product$thumb.url})`
  }, [_c('div', {
    staticClass: "square-img__badge square-img__badge--sm grey darken-4"
  }, [_vm._v(_vm._s(_vm.index))])])])], 1), _c('v-col', {
    staticStyle: {
      "flex": "0 0 calc(100% - 114px)",
      "max-width": "calc(100% - 114px)"
    },
    attrs: {
      "cols": "auto"
    }
  }, [(_vm$product2 = _vm.product) !== null && _vm$product2 !== void 0 && (_vm$product2$flags = _vm$product2.flags) !== null && _vm$product2$flags !== void 0 && _vm$product2$flags.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold text-truncate"
  }, [_vm._v(_vm._s(_vm.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('div', {
    staticClass: "txt txt--sm txt--dark text-truncate mt-4px"
  }, [_vm._v(_vm._s((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name))]), _c('div', {
    staticClass: "mt-6px mt-md-10px"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": ((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$meta = _vm$product4.meta) === null || _vm$product4$meta === void 0 ? void 0 : (_vm$product4$meta$rev = _vm$product4$meta.reviews) === null || _vm$product4$meta$rev === void 0 ? void 0 : _vm$product4$meta$rev.star__average) || 0
    }
  })], 1)])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }