<template>
    <div class="header-notice">
        <v-slide-y-transition v-for="item in items" :key="item._id">
            <v-row v-show="item._id == selected" class="header-notice-item">
                <v-col cols="auto" @click="go(item)">[공지사항] {{ item.subject }}</v-col>
                <v-col cols="auto" @click="go(item)">{{ item.createdAt.toDate() }}</v-col>
                <v-col cols="auto" class="header-notice-item__button-wrap">
                    <v-btn x-small elevation="0" color="white" class="header-notice-item__button">
                        <v-icon size="16" :color="isUpDisabled ? 'grey lighten-2' : 'grey darken-1'" @click="up">mdi-chevron-up</v-icon>
                    </v-btn>
                    <v-btn x-small elevation="0" color="white" class="header-notice-item__button">
                        <v-icon size="16" :color="isDownDisabled ? 'grey lighten-2' : 'grey darken-1'" @click="down">mdi-chevron-down</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-slide-y-transition>
    </div>
</template>

<script>
import api from "@/api";
export default {
    computed: {
        isUpDisabled() {
            return this.selected == this.items[0]?._id;
        },
        isDownDisabled() {
            return this.selected == this.items[this.items.length - 1]?._id;
        },
    },
    data() {
        return {
            items: [],
            selected: null,
        };
    },
    methods: {
        async search() {
            const { notifications } = await api.v1.center.notifications.getNotifications();

            this.items = notifications;
            this.selected = notifications[0]._id;
        },
        down() {
            if (this.isDownDisabled) return;

            const index = this.items.findIndex(({ _id }) => _id == this.selected);
            this.selected = this.items[index + 1]?._id;
        },
        up() {
            if (this.isUpDisabled) return;
            const index = this.items.findIndex(({ _id }) => _id == this.selected);
            this.selected = this.items[index - 1]?._id;
        },
        go({ _id }) {
            const path = `/center/notice/${_id}`;
            if (this.$route.path !== path) this.$router.push(path);
        },
    },
    mounted() {
        this.search();
    },
};
</script>