<template>
    <page-section>
        <v-row>
            <v-col cols="12" sm="6" lg="4">
                <v-card img="/images/main/pangyo-showroom-bg.jpg" class="h-100">
                    <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1 / 1 : ''">
                        <div class="d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px">
                            <div class="mb-4px mb-md-8px">
                                <v-chip small color="grey darken-4"><b>판교 오프라인 쇼룸</b></v-chip>
                            </div>
                            <div class="tit-wrap mb-0">
                                <h2 class="tit white--text">판교 쇼룸</h2>
                                <p class="txt txt--sm white--text line-height-135">판교 쇼룸에서 다양한 제품을 체험하고 직접 구매</p>
                            </div>
                            <div class="mt-30px mt-lg-auto">
                                <v-btn v-bind="{ ...btn_secondary2, ...$attrs }" small target="_blank" href="https://map.naver.com/p/search/%EC%A1%B0%EC%9D%B4%ED%8F%AC%EB%9D%BC%EC%9D%B4%ED%94%84/place/1654966042?c=15.00,0,0,0,dh">오시는 길</v-btn>
                            </div>
                        </div>
                    </v-responsive>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-card img="/images/main/concierge-bg.jpg" class="h-100">
                    <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1 / 1 : ''">
                        <div class="d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px">
                            <div class="mb-4px mb-md-8px">
                                <v-chip small color="grey darken-4"><b>컨시어지 서비스</b></v-chip>
                            </div>
                            <div class="tit-wrap mb-0">
                                <h2 class="tit white--text">컨시어지 서비스</h2>
                                <p class="txt txt--sm white--text line-height-135">문의만 하면 고객님께 맞춤형 상품 추천</p>
                            </div>
                            <div class="mt-30px mt-lg-auto">
                                <v-btn v-bind="{ ...btn_secondary2, ...$attrs }" small to="/concierge-service">자세히</v-btn>
                            </div>
                        </div>
                    </v-responsive>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" lg="4">
                <v-card img="/images/main/online-showroom-bg.jpg" class="h-100">
                    <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1 / 1 : ''">
                        <div class="d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px">
                            <div class="mb-4px mb-md-8px">
                                <v-chip small color="grey darken-4"><b>온라인 쇼룸</b></v-chip>
                            </div>
                            <div class="tit-wrap mb-0">
                                <h2 class="tit white--text">온라인 쇼룸</h2>
                                <p class="txt txt--sm white--text line-height-135">온라인 쇼룸에서 한번에 상품비교 가능</p>
                            </div>
                            <div class="mt-30px mt-lg-auto">
                                <v-btn v-bind="{ ...btn_secondary2, ...$attrs }" to="/shop/showrooms">온라인 쇼룸 체험</v-btn>
                            </div>
                        </div>
                    </v-responsive>
                </v-card>
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { btn_secondary2 } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";

export default {
    props: {},
    components: {
        PageSection,
    },
    data: () => {
        return {
            btn_secondary2,
        };
    },
    mounted() {},
    methods: {},
};
</script>
