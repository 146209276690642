var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-displays', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var products = _ref.products;
        return [_c('div', {
          staticClass: "main-products"
        }, [_c('v-row', {
          staticClass: "product-items"
        }, _vm._l(products, function (product, index) {
          return _c('shop-product-item', {
            key: index,
            attrs: {
              "cols": "6",
              "sm": "4",
              "md": "3",
              "lg": "4",
              "value": product
            }
          });
        }), 1)], 1)];
      }
    }])
  }, 'main-displays', {
    code: _vm.code
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }