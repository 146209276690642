var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main",
    scopedSlots: _vm._u([{
      key: "bandBanner",
      fn: function () {
        return [_c('main-band-banners')];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "top-link"
        }, [_c('v-container', {
          staticClass: "container--lg"
        }, [_c('ul', {
          staticClass: "top-link__lists"
        }, [_c('li', {
          staticClass: "top-link__list"
        }, [_c('router-link', {
          staticClass: "top-link__list-link",
          attrs: {
            "to": {
              path: '/shop',
              query: {
                groups: _vm.DISPLAY_CODES.BEST_SELLER.value
              }
            }
          }
        }, [_vm._v("베스트")])], 1), _c('li', {
          staticClass: "top-link__list"
        }, [_c('router-link', {
          staticClass: "top-link__list-link",
          attrs: {
            "to": "/shop/exhibitions?group=BARGAIN_SALES"
          }
        }, [_vm._v("특가")])], 1), _c('li', {
          staticClass: "top-link__list"
        }, [_c('router-link', {
          staticClass: "top-link__list-link",
          attrs: {
            "to": "/shop/exhibitions?group=PLANNED_SALES"
          }
        }, [_vm._v("프로모션")])], 1), _c('li', {
          staticClass: "top-link__list"
        }, [_c('router-link', {
          staticClass: "top-link__list-link",
          attrs: {
            "to": "/community/snse"
          }
        }, [_vm._v("SNS이벤트")])], 1)])])], 1)];
      },
      proxy: true
    }])
  }, [_c('main-visual', {
    staticClass: "page-section--first pb-0"
  }), _c('main-products-popular'), _c('v-divider'), _c('main-exhibition'), _c('v-divider'), _c('main-showroom-service'), _c('v-divider'), _c('main-products-best'), _c('v-divider'), _c('main-reviews-best', {
    staticClass: "page-section--last"
  }), _vm._l(_vm.popups, function (popup) {
    return _c('popup-layer', {
      key: popup._id,
      attrs: {
        "popup": popup
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }