var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-row', {
    staticClass: "row--main-exhibition"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('main-exhibition-banner', {
    attrs: {
      "code": _vm.BANNERS.MAIN_3.code__pc,
      "isPc": ""
    }
  }), _c('main-exhibition-banner', {
    attrs: {
      "code": _vm.BANNERS.MAIN_3.code__mb,
      "isMb": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--exhibition-card",
    style: _vm.$vuetify.breakpoint.mdAndUp ? 'height:calc(100% + 20px)' : ''
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "xl": "12"
    }
  }, [_c('v-card', {
    staticClass: "exhibition-card",
    staticStyle: {
      "background-image": "url('/images/main/onestop-bg.png')"
    },
    attrs: {
      "color": "#E1E5E8",
      "to": "/center/delivery-installation-service"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 655 / 240
    }
  }, [_c('div', {
    staticClass: "exhibition-card__inner"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_c('b', [_vm._v("One-stop Service")])])], 1), _c('div', {
    staticClass: "tit-wrap mb-0"
  }, [_c('h2', {
    staticClass: "tit font-size-lg-26 font-size-xl-36"
  }, [_vm._v("원스탑 서비스")]), _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-135 mt-6px mt-xl-12px"
  }, [_vm._v("주문·배송·설치까지 한번에 제공해주는 토탈 서비스")]), _c('div', {
    staticClass: "pt-12px pt-md-20px"
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "small": "",
      "text": "",
      "color": "grey darken-4"
    }
  }, [_vm._v("더 보기"), _c('v-icon', {
    staticClass: "pl-4px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "xl": "12"
    }
  }, [_c('v-card', {
    staticClass: "exhibition-card",
    staticStyle: {
      "background-image": "url('/images/main/exclusive-bg.png')"
    },
    attrs: {
      "color": "#EEEEEE",
      "to": "/shop/exhibitions?group=BARGAIN_SALES"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 655 / 240
    }
  }, [_c('div', {
    staticClass: "exhibition-card__inner"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "secondary"
    }
  }, [_c('b', [_vm._v("Exclusive Sale")])])], 1), _c('div', {
    staticClass: "tit-wrap mb-0"
  }, [_c('h2', {
    staticClass: "tit font-size-lg-26 font-size-xl-36"
  }, [_vm._v("온라인 특가 상품")]), _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-135 mt-6px mt-xl-12px"
  }, [_vm._v("조이포라이프 스페셜 특가 제품 모음")]), _c('div', {
    staticClass: "pt-12px pt-md-20px"
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "small": "",
      "text": "",
      "color": "grey darken-4"
    }
  }, [_vm._v("더 보기"), _c('v-icon', {
    staticClass: "pl-4px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)])])])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }