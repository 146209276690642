<template>
    <router-link class="link" :to="to">
        <slot v-bind:carts="carts"></slot>
    </router-link>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        to: { type: String, default: null },
    },
    computed: {
        ...mapState(["carts"]),
    },
};
</script>
