<template>
    <page-section class="page-section--xs pb-0">
        <v-btn v-bind="{ ...btn_secondary, ...$attrs }" v-on="{ click }" block class="v-size--xx-large"> GO TO TOP <icon-arrow-rounded direction="up" class="ml-12px" /> </v-btn>
    </page-section>
</template>

<script>
import { btn_secondary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import IconArrowRounded from "@/components/client/icons/icon-arrow-rounded.vue";

export default {
    components: {
        PageSection,
        IconArrowRounded,
    },
    data: () => {
        return {
            btn_secondary,
        };
    },
    methods: {
        click() {
            this.$vuetify.goTo(0, { duration: 500, offset: 0, easing: "easeInOutCubic" });
        },
    },
};
</script>

<style></style>
