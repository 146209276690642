<template>
    <footer class="footer">
        <div class="footer-head">
            <v-container class="container--lg">
                <v-row align="center" justify="space-between">
                    <v-col cols="12" sm="auto">
                        <div class="divider-group divider-group--large">
                            <ul class="white--text">
                                <li><router-link to="/about">회사소개</router-link></li>
                                <li><router-link to="/mypage/questions">1:1문의</router-link></li>
                                <li><router-link to="/center/faq">자주묻는 질문</router-link></li>
                                <li><router-link to="/privacy-policy">개인정보처리방침 </router-link></li>
                                <li><router-link to="/terms">이용약관</router-link></li>
                                <li><router-link to="/form/inquiry-partner">제휴문의</router-link></li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="auto" class="ml-auto">
                        <footer-family-site />
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="footer-body">
            <v-container class="container--lg">
                <v-row class="row--sm">
                    <v-col cols="12">
                        <div class="inline-group">
                            <ul>
                                <li><div class="font-size-14 font-weight-medium white--text mb-12px">조이포라이프 공식몰</div></li>
                                <li class="clear"><span class="grey--text">상호명 </span>조이포라이프 주식회사</li>
                                <li><span class="grey--text">대표이사 </span>하기호</li>
                                <li><span class="grey--text">주소 </span>경기도 성남시 대왕판교로 187 / 경기도 화성시 장안면 장안로 480-192</li>
                                <li><span class="grey--text">전자우편주소 </span>jflmall@joeyforlife.com</li>
                                <li><span class="grey--text">팩스 </span>070-5038-4807</li>
                                <li class="clear"><span class="grey--text">사업자등록번호 </span>120-86-43097</li>
                                <li><span class="grey--text">통신판매업신고 </span>제2016-화성장안-01호</li>
                                <li><span class="grey--text">개인정보책임관리자 </span>하기호</li>
                                <!-- <li><span class="grey--text">고객만족센터 </span>1644-2350 (운영시간 09:00~18:00, 점심시간 12:00~13:00)</li> -->
                                <li><span class="grey--text">CS직통번호 </span>070-4010-5061</li>
                                <li class="py-md-0"><span class="footer-account">무통장입금 기업은행 960-004190-04-040</span></li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="footer-copyright">
                            <p>당사의 모든 제작물의 저작권은 조이포라이프 몰에 있으며, 무단복제나 도용은 저작권법(97조5항)에 의해 금지되어 있습니다. 이를 위반 시 법적인 처벌을 받을 수 있습니다.</p>
                            <p>Copyright 2023. JOEYFORLIFE CO.,Ltd. All Rights Reserved.</p>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="mt-10px">
                            <v-row align="center" class="row--sm">
                                <v-col cols="auto">
                                    <u-dialog v-model="shows">
                                        <template #activator="{ attrs, on }">
                                            <v-img v-bind="{ attrs, on }" v-on="on" width="60" :aspect-ratio="150 / 175" src="/images/nicepay-logo.png" class="cursor-pointer" />
                                        </template>
                                        <v-img src="/images/nicepay-img.png" />
                                    </u-dialog>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <!-- <div class="footer-foot">
            <v-container>
                <div class="footer-copyright">
                    <p>당사의 모든 제작물의 저작권은 조이포라이프에 있으며, 무단복제나 도용은 저작권법(97조5항)에 의해 금지되어 있습니다. 이를 위반 시 법적인 처벌을 받을 수 있습니다.</p>
                    <p>Copyright 2023. JOEYFORLIFE CO.,Ltd. All Rights Reserved.</p>
                </div>
            </v-container>
        </div> -->

        <!-- <div class="floating-menu">
            <a href="https://www.voxels.com/play?coords=SW@949E,208S" target="_blank" class="floating-menu__btn">
                <v-img src="/images/common/floating-meta.png" />
            </a>
            <a href="#" target="_blank" class="floating-menu__btn">
                <v-img src="/images/common/floating-njob.png" />
            </a>
            <button class="floating-menu__btn floating-menu__scroll-top" @click="$vuetify.goTo(target, options)">
                <icon-arrow-rounded direction="up" class="grey--text text--darken-4" />
            </button>
        </div> -->
    </footer>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import IconArrowRounded from "../icons/icon-arrow-rounded.vue";
import FooterFamilySite from './footer-family-site.vue';

export default {
    components: {
        UDialog,
        IconArrowRounded,
        FooterFamilySite,
    },
    data: () => {
        return {
            attrs_input,

            carts: [],

            searchKey: "name",
            searchValue: null,

            shows: false,

            // scrollTop
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            this.$nextTick(function () {
                this.footer();
            });
        },

        // footer
        footer: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
                scrollTop();
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                }
                scrollAnimate();
            }

            // Scroll Top
            function scrollTop() {
                if (scrollPosition > window.innerHeight / 2) {
                    HTML.setAttribute("data-scroll-top", "true");
                } else {
                    HTML.removeAttribute("data-scroll-top");
                }
            }
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>