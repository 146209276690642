var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "h-100",
    attrs: {
      "img": "/images/main/pangyo-showroom-bg.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 1 / 1 : ''
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "grey darken-4"
    }
  }, [_c('b', [_vm._v("판교 오프라인 쇼룸")])])], 1), _c('div', {
    staticClass: "tit-wrap mb-0"
  }, [_c('h2', {
    staticClass: "tit white--text"
  }, [_vm._v("판교 쇼룸")]), _c('p', {
    staticClass: "txt txt--sm white--text line-height-135"
  }, [_vm._v("판교 쇼룸에서 다양한 제품을 체험하고 직접 구매")])]), _c('div', {
    staticClass: "mt-30px mt-lg-auto"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": "",
      "target": "_blank",
      "href": "https://map.naver.com/p/search/%EC%A1%B0%EC%9D%B4%ED%8F%AC%EB%9D%BC%EC%9D%B4%ED%94%84/place/1654966042?c=15.00,0,0,0,dh"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary2, _vm.$attrs), false), [_vm._v("오시는 길")])], 1)])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "h-100",
    attrs: {
      "img": "/images/main/concierge-bg.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 1 / 1 : ''
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "grey darken-4"
    }
  }, [_c('b', [_vm._v("컨시어지 서비스")])])], 1), _c('div', {
    staticClass: "tit-wrap mb-0"
  }, [_c('h2', {
    staticClass: "tit white--text"
  }, [_vm._v("컨시어지 서비스")]), _c('p', {
    staticClass: "txt txt--sm white--text line-height-135"
  }, [_vm._v("문의만 하면 고객님께 맞춤형 상품 추천")])]), _c('div', {
    staticClass: "mt-30px mt-lg-auto"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": "",
      "to": "/concierge-service"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary2, _vm.$attrs), false), [_vm._v("자세히")])], 1)])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "h-100",
    attrs: {
      "img": "/images/main/online-showroom-bg.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 1 / 1 : ''
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "grey darken-4"
    }
  }, [_c('b', [_vm._v("온라인 쇼룸")])])], 1), _c('div', {
    staticClass: "tit-wrap mb-0"
  }, [_c('h2', {
    staticClass: "tit white--text"
  }, [_vm._v("온라인 쇼룸")]), _c('p', {
    staticClass: "txt txt--sm white--text line-height-135"
  }, [_vm._v("온라인 쇼룸에서 한번에 상품비교 가능")])]), _c('div', {
    staticClass: "mt-30px mt-lg-auto"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": "/shop/showrooms"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary2, _vm.$attrs), false), [_vm._v("온라인 쇼룸 체험")])], 1)])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }