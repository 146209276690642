var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "outlined": "",
      "color": "grey darken-4",
      "to": "/mypage/coupons"
    }
  }, [_c('div', {
    staticClass: "px-16px py-14px"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs txt--dark font-weight-medium",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("보유 쿠폰")])]), _c('v-spacer'), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20",
      "color": "grey darken-4"
    }
  }, [_vm._v("mdi-chevron-right")])], 1), _vm.coupons.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._l(_vm.coupons, function (coupon) {
    return [_c('div', {
      key: coupon._id,
      staticClass: "primary--text font-weight-regular"
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v("·")]), _c('v-col', [_vm._v(_vm._s(coupon.name))])], 1)], 1)];
  })], 2) : _vm._e()], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }