var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-row', {
    staticClass: "mx-n10px my-n10px mx-md-n20px mx-lg-n30px"
  }, [_c('v-col', {
    staticClass: "px-10px py-10px px-md-20px px-lg-30px",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "mw-lg-280px"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(_vm._s(_vm.CODE.text))]), _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-135"
  }, [_vm._v("이번 달 가장 핫한 제품만 모았습니다!")]), _c('div', {
    staticClass: "pt-12px pt-md-20px"
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "small": "",
      "text": "",
      "color": "primary",
      "to": {
        path: '/shop',
        query: {
          groups: _vm.code
        }
      }
    }
  }, [_vm._v("더 보기"), _c('v-icon', {
    staticClass: "pl-4px"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)]), _c('div', [_c('main-products-popular-categories', _vm._b({}, 'main-products-popular-categories', {
    code: _vm.code
  }, false))], 1)])]), _c('v-col', {
    staticClass: "px-10px py-10px px-md-20px px-lg-30px",
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'max-width: calc(100% - 340px);' : '',
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('main-products-popular-displays', _vm._b({}, 'main-products-popular-displays', {
    code: _vm.code
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }