var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "최근 본 상품"
    }
  }), _vm.items.length ? [_c('div', {
    staticClass: "side-slide"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.items, function (item) {
    var _item$product, _item$product$thumb;
    return [_c('swiper-slide', {
      key: item._id
    }, [_c('v-card', {
      attrs: {
        "to": `/shop/products/${item._product}`
      }
    }, [_c('div', {
      staticClass: "side-slide__img",
      style: `background-image: url('${(_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url}')`
    })])], 1)];
  }), _c('div', {
    staticClass: "swiper-pagination swiper-pagination--dark",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })], 2)], 1)] : [_c('v-col', {
    staticClass: "text-center"
  }, [_vm._v(" 최근 본 상품이 없습니다 ")])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }