var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.items, function (item) {
    return _c('band-banner', _vm._b({
      key: item._id
    }, 'band-banner', {
      item,
      isPc: _vm.isPc
    }, false));
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }