<template>
    <v-responsive :aspect-ratio="1030 / 446">
        <v-card outlined class="h-100 rounded-b-0 overflow-hidden">
            <v-row no-gutters class="h-100">
                <v-col cols="12" md="auto" order-md="2">
                    <div class="w-md-230px h-100 grey lighten-5">
                        <div class="d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px">
                            <v-btn icon class="transparent" style="position: absolute; top: 0; right: 0" @click="$emit('close')"><v-icon size="20" color="grey darken-4">mdi-close</v-icon></v-btn>
                            <br class="d-none d-lg-block d-xl-none" />
                            <!-- <p class="txt txt--sm txt--dark">{{ asideData?.desc }}</p> -->
                            <p class="txt txt--sm txt--dark">조이포라이프의 진심. 노약자, 장애인을 위한 의료보조기구 엔젤케어 시리즈 3종</p>
                            <div class="mt-20px mt-md-auto">
                                <!-- <v-btn v-bind="asideBtnAttrs" block>{{ asideData?.text }}</v-btn> -->
                                <v-btn v-bind="asideBtnAttrs" block to="/shop/products?category=angelcare">엔젤케어 보러가기</v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="" order-md="1">
                    <div class="d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px">
                        <tit-wrap-subtitle titSize="xs">
                            <template #tit>케어 제품</template>
                            <template #subTit>조이포라이프의 진심</template>
                        </tit-wrap-subtitle>

                        <main-visual-tab-care-banners :code="BANNERS.MAIN_6.code__pc" isPc />
                        <main-visual-tab-care-banners :code="BANNERS.MAIN_6.code__mb" isMb />

                        <!-- <v-row align="center">
                            <v-col cols="6" sm="4" md="3">
                                <v-card to="/">
                                    <div class="square-img" style="background-image: url(/images/main/mainvisual-img.jpg)" />
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <v-card to="/">
                                    <div class="square-img" style="background-image: url(/images/main/mainvisual-img2.jpg)" />
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <v-card to="/">
                                    <div class="square-img" style="background-image: url(/images/main/mainvisual-img3.jpg)" />
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="4" md="3">
                                <v-card to="/">
                                    <div class="square-img" style="background-image: url(/images/main/mainvisual-img4.jpg)" />
                                </v-card>
                            </v-col>
                        </v-row> -->
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-responsive>
</template>

<script>
import { BANNERS, btn_primary, initSetting } from "@/assets/variables";

import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import MainVisualTabCareBanners from "./main-visual-tab-care-banners.vue";
import { mapState } from "vuex";

export default {
    props: {},
    components: {
        TitWrapSubtitle,
        MainVisualTabCareBanners,
    },
    data: () => ({
        BANNERS,
    }),
    computed: {
        ...mapState({ asideData: (state) => initSetting(state?.setting).main.care }),
        asideBtnAttrs() {
            const { link, isOuter } = this.asideData;
            const attrs = { ...btn_primary };
            if (isOuter) {
                attrs.href = link;
                attrs.target = "_blank";
            } else attrs.to = link;
            return attrs;
        },
    },
};
</script>

<style lang="scss" scoped></style>
