<template>
    <main-displays v-bind="{ code }">
        <template #default="{ products }">
            <div class="main-products">
                <v-row class="product-items">
                    <shop-product-item v-for="(product, index) in products" :key="index" cols="6" sm="4" md="3" lg="4" :value="product" />
                </v-row>
            </div>
        </template>
    </main-displays>
</template>

<script>
import MainDisplays from "@/components/client/main/main-displays.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";

export default {
    components: {
        MainDisplays,
        ShopProductItem,
    },
    props: {
        code: { type: String, default: null },
    },
};
</script>

<style>
</style>