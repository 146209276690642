<template>
    <main-displays v-bind="{ code }">
        <template #default="{ products }">
            <v-row>
                <template v-for="(product, index) in products">
                    <v-col cols="12" :key="index">
                        <router-link :to="`/shop/products/${product._id}`">
                            <v-row align="center" class="flex-nowrap">
                                <v-col cols="auto" style="flex: 0 0 152px">
                                    <v-card width="100%">
                                        <div class="square-img" :style="`background-image: url(${product?.thumb?.url})`" />
                                    </v-card>
                                </v-col>
                                <v-col cols="auto" style="flex: 0 0 calc(100% - 152px); max-width: calc(100% - 152px)">
                                    <div class="txt txt--sm txt--dark font-weight-bold text-truncate" v-if="product?.flags?.length">{{ product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                                    <div class="txt txt--sm txt--dark text-truncate mt-4px">{{ product.name }}</div>
                                    <div class="mt-6px mt-md-10px">
                                        <div class="d-flex align-center">
                                            <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="product?.meta?.reviews?.star__average || 0" />
                                            <span class="font-size-14 grey--text text--lighten-1 pl-4px">({{ product?.meta?.reviews?.count__total || 0 }})</span>
                                        </div>
                                    </div>
                                    <div class="mt-8px mt-md-10px">
                                        <span v-if="getDiscountPrice(product)" class="font-size-14 grey--text text--lighten-1 text-decoration-line-through">{{ product.price.format() }}</span>
                                        <em v-if="!product.sells || product.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                                        <div class="d-flex flex-wrap align-center font-size-14 font-size-lg-16 font-weight-bold">
                                            <span v-if="getDiscountRate(product)" class="secondary--text mr-4px mr-md-8px"> {{ getDiscountRate(product) }}% </span>
                                            <span class="d-flex flex-wrap align-center grey--text text--darken-4">
                                                {{ getSalePrice(product).format() }}
                                                <small class="font-size-14 font-weight-regular pl-2px">원</small>
                                            </span>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </router-link>
                    </v-col>
                </template>
            </v-row>
        </template>
    </main-displays>
</template>

<script>
import { mapGetters } from 'vuex';

import MainDisplays from "@/components/client/main/main-displays.vue";

export default {
    components: {
        MainDisplays,
    },
    props: {
        code: { type: String, default: null },
    },
    computed: {
        ...mapGetters(["getDiscountPrice", "getDiscountRate", "getSalePrice"]),
    },
};
</script>

<style>
</style>