var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_vm._l(_vm.categories, function (_ref) {
    var text = _ref.text,
      value = _ref.value;
    return [_c('v-col', {
      key: value,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "value": value,
        "color": "grey darken-4",
        "hide-details": "auto",
        "label": text
      },
      model: {
        value: _vm.form.category,
        callback: function ($$v) {
          _vm.$set(_vm.form, "category", $$v);
        },
        expression: "form.category"
      }
    })], 1)];
  })], 2)], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "이름",
      "rules": _vm.rules.name
    },
    model: {
      value: _vm.form.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer, "name", $$v);
      },
      expression: "form.writer.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "rules": _vm.rules.phone,
      "placeholder": "연락처"
    },
    model: {
      value: _vm.form.writer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.writer, "phone", $$v);
      },
      expression: "form.writer.phone"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('terms-of-agreements-short', {
    attrs: {
      "hideItems": ""
    },
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.form.meta._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "_terms", $$v);
      },
      expression: "form.meta._terms"
    }
  })], 1)], 1), _c('div', [_c('v-btn', _vm._b({
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs, {
    disabled: _vm.disabled,
    loading: _vm.loading
  }), false), [_vm._v("서비스 신청하기")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }