var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    staticClass: "family-site-list",
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-select', _vm._g(_vm._b({
          staticClass: "white--text",
          attrs: {
            "menu-props": "openOnClick: false",
            "item-disabled": "",
            "dense": "",
            "color": "grey",
            "placeholder": "Family Site"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_input, {
          attrs
        }), false), on))];
      }
    }])
  }, [_c('v-list', {
    staticClass: "pa-0"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-list-item', {
      key: item,
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }