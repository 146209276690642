<template>
    <page-section class="page-section--sm py-0">
        <main-aside-banner-item :code="BANNERS.MAIN_5.code__pc" isPc />
    </page-section>
</template>

<script>
import { BANNERS } from "@/assets/variables";

import PageSection from "@/components/client//templates/page-section.vue";
import MainAsideBannerItem from "@/components/client//inc/main-aside/main-aside-banner-item.vue";

export default {
    components: {
        PageSection,
        MainAsideBannerItem,
    },
    data: () => ({
        BANNERS,
    }),
};
</script>
