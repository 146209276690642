<template>
    <v-col cols="12" sm="4">
        <router-link :to="`/shop/products/${product._id}`">
            <v-card class="mx-auto">
                <div class="square-img" :style="`background-image: url(${product?.thumb?.url})`">
                    <div class="square-img__badge">{{ index }}</div>
                </div>
            </v-card>
            <div class="pt-10px pt-md-20px">
                <div class="txt txt--sm txt--dark font-weight-bold text-truncate" v-if="product?.flags?.length">{{ product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                <div class="txt txt--sm txt--dark text-truncate mt-4px">{{ product?.name }}</div>
                <div class="mt-10px mt-md-20px">
                    <v-card outlined class="border-dark">
                        <div class="pa-20px">
                            <div class="pb-8px pb-md-12px">
                                <v-row align="center" class="row--xs">
                                    <v-col cols="auto">
                                        <v-card rounded="circle" color="grey lighten-4" class="w-40px">
                                            <v-img :aspect-ratio="1 / 1">
                                                <div class="d-flex align-center justify-center h-100">
                                                    <i class="icon icon-heart"></i>
                                                </div>
                                            </v-img>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="auto">
                                        <div class="txt txt--xs txt--dark">
                                            <span class="d-inline-flex align-center">
                                                <b class="font-weight-bold">{{ review?.user?.username }}</b
                                                ><span class="pl-2px">님</span>
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="review?.star" />
                            <div class="pt-8px">
                                <p class="txt txt--xs text-truncate-2">{{ review?.content }}</p>
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>
        </router-link>
    </v-col>
</template>

<script>
import { initProduct, initShopReview } from "@/assets/variables";

import IconPerson from "@/components/client/icons/icon-person.vue";

export default {
    components: {
        IconPerson,
    },
    props: {
        index: { type: String, default: "01" },
        review: { type: Object, default: initShopReview },
        product: { type: Object, default: initProduct },
    },
};
</script>

<style>
</style>