<template>
    <page-section class="page-section--sm pb-0">
        <tit-wrap-small title="최근 본 상품">
            <!-- <v-btn x-small text color="grey" class="v-btn--text-underline" to="/mypage/cart">더 보기</v-btn> -->
        </tit-wrap-small>

        <template v-if="items.length">
            <div class="side-slide">
                <swiper class="swiper" :options="swiperOptions">
                    <template v-for="item in items">
                        <swiper-slide :key="item._id">
                            <v-card :to="`/shop/products/${item._product}`">
                                <div class="side-slide__img" :style="`background-image: url('${item.product?.thumb?.url}')`"></div>
                            </v-card>
                        </swiper-slide>
                    </template>
                    <div class="swiper-pagination swiper-pagination--dark" slot="pagination"></div>
                </swiper>
            </div>
        </template>
        <template v-else>
            <v-col class="text-center"> 최근 본 상품이 없습니다 </v-col>
        </template>
    </page-section>
</template>

<script>
import api from "@/api";
import { mapMutations, mapState } from "vuex";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/components/client//templates/page-section.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";

const swiperOptions = {
    allowTouchMove: true,
    centeredSlides: false,
    slidesPerView: 3,
    spaceBetween: 12,
    // autoplay: {
    //     delay: 5000,
    //     waitForTransition: true,
    //     disableOnInteraction: false,
    // },
    speed: 500,
    lazy: {
        loadPrevNext: true,
    },
    loop: false,
    pagination: {
        el: ".side-slide .swiper-pagination",
        clickable: true,
    },
};

export default {
    components: {
        Swiper,
        SwiperSlide,
        PageSection,
        TitWrapSmall,
    },
    data: () => ({
        loading: false,
        swiperOptions,
    }),
    computed: {
        ...mapState(["watches"]),
        items() {
            return [...this.watches].slice(0, 10);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations(["setWatches"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const _watches = (this.$store?.state?.watches || []).map(({ _id }) => _id);
                const watches = (await api.v1.me.watches.gets({ headers: { limit: 15 }, params: { _watches } }))?.watches;
                this.setWatches({ watches });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .swiper-pagination {
        position: relative;
        bottom: auto !important;
        left: auto !important;
        justify-content: left;
        margin-top: 20px;
    }
}
</style>
