<template>
    <page-section class="page-section--sm pb-0">
        <tit-wrap-small title="고객서비스">
            <v-btn x-small text color="grey" class="v-btn--text-underline" to="/center/notice">더 보기</v-btn>
        </tit-wrap-small>

        <v-card outlined color="grey darken-4">
            <div class="pa-16px">
                <v-row align="center" class="row--sm txt txt--xs txt--dark font-weight-medium text-center">
                    <v-col>
                        <a href="#" class="d-flex flex-column justify-center" @click.prevent="showMessenger">
                            <span class="material-icons-outlined font-size-20">chat</span>
                            <div class="mt-8px">실시간 채팅</div>
                        </a>
                    </v-col>
                    <v-col cols="auto" class="align-self-stretch"><v-divider vertical class="h-100" /></v-col>
                    <v-col>
                        <router-link to="/mypage/questions" class="d-flex flex-column justify-center">
                            <span class="material-icons-outlined font-size-20">person</span>
                            <div class="mt-8px">1:1 상담</div>
                        </router-link>
                    </v-col>
                    <v-col cols="auto" class="align-self-stretch"><v-divider vertical class="h-100" /></v-col>
                    <v-col>
                        <router-link to="/center/notice" class="d-flex flex-column justify-center">
                            <span class="material-icons-outlined font-size-20">call</span>
                            <div class="mt-8px">고객센터</div>
                        </router-link>
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <v-card color="grey lighten-5" class="mt-12px">
            <div class="pa-20px">
                <v-row class="row--sm txt txt--xs">
                    <v-col cols="12">
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <div class="txt--dark font-weight-medium">전화</div>
                            </v-col>
                            <v-col>
                                <!-- <div>1644-2350<br/>(CS직통번호 070-4010-5061)​</div> -->
                                <div>070-4010-5061​</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <div class="txt--dark font-weight-medium">메일</div>
                            </v-col>
                            <v-col>
                                <div>jflmall@joeyforlife.com​</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <div class="txt--dark font-weight-medium">평일</div>
                            </v-col>
                            <v-col>
                                <div>오전 08:00 - 오후 05:00​</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <div class="txt--dark font-weight-medium">점심</div>
                            </v-col>
                            <v-col>
                                <div>오후 12:00 - 오후 01:00​</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <div class="txt--dark font-weight-medium">휴무</div>
                            </v-col>
                            <v-col>
                                <div>토/일/공휴일은 휴무​</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </page-section>
</template>

<script>
import PageSection from "@/components/client//templates/page-section.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";

export default {
    components: {
        PageSection,
        TitWrapSmall,
    },
    methods: {
        showMessenger() {
            window?.ChannelIO?.("showMessenger");
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .swiper-pagination {
        position: relative;
        bottom: auto !important;
        left: auto !important;
        justify-content: left;
        margin-top: 20px;
    }
}
</style>
