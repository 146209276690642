var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner && _vm.shows ? _c('v-card', {
    staticClass: "main-banner overflow-hidden"
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.slides, function (slide, index) {
    return [_c('swiper-slide', {
      key: index
    }, [_c('v-card', {
      staticClass: "overflow-hidden",
      attrs: {
        "to": slide !== null && slide !== void 0 && slide.isOuterLink ? undefined : (slide === null || slide === void 0 ? void 0 : slide.url) || undefined,
        "href": slide !== null && slide !== void 0 && slide.isOuterLink ? (slide === null || slide === void 0 ? void 0 : slide.url) || undefined : undefined,
        "target": slide === null || slide === void 0 ? void 0 : slide.target
      }
    }, [_c('div', {
      staticClass: "main-banner__img",
      style: `background-image:url(${slide === null || slide === void 0 ? void 0 : slide.image});`
    })])], 1)];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination"
  })])], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }