var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1030 / 446
    }
  }, [_c('v-card', {
    staticClass: "h-100 rounded-b-0 overflow-hidden"
  }, [_c('v-btn', {
    staticClass: "transparent",
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "right": "0"
    },
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20",
      "color": "white"
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-row', {
    staticClass: "h-100",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.link, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "main-community pa-20px pa-md-30px pa-lg-40px",
      style: 'background-image: url(' + item.image + ');'
    }, [_c('div', {
      staticClass: "w-100 mb-20px mb-md-40px"
    }, [_c('h3', {
      staticClass: "tit tit--xs white--text line-height-1"
    }, [_vm._v(_vm._s(item.title))]), _c('p', {
      staticClass: "txt txt--sm white--text mt-10px mt-md-20px",
      domProps: {
        "innerHTML": _vm._s(item.txt)
      }
    })]), _c('div', [_c('v-btn', _vm._b({
      attrs: {
        "to": item.link
      }
    }, 'v-btn', Object.assign({}, _vm.btn_secondary2, _vm.$attrs), false), [_vm._v("더 보기")])], 1)])]);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }