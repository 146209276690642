var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1030 / 446
    }
  }, [_c('v-card', _vm._b({
    staticClass: "h-100 rounded-b-0 overflow-hidden",
    attrs: {
      "outlined": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    staticClass: "flex-md-nowrap h-100",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "w-md-230px h-100 grey lighten-5"
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px"
  }, [_c('v-btn', {
    staticClass: "transparent",
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "right": "0"
    },
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20",
      "color": "grey darken-4"
    }
  }, [_vm._v("mdi-close")])], 1), _c('br', {
    staticClass: "d-none d-lg-block d-xl-none"
  }), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("조이포라이프몰과 함께하는전세계 브랜드를 이곳에서 모두 만나보실 수 있습니다.")]), _c('div', {
    staticClass: "mt-20px mt-md-auto"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "to": "/shop/brands"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("브랜드관 바로가기")])], 1)], 1)])]), _c('v-col', {
    staticStyle: {
      "width": "calc()"
    },
    attrs: {
      "cols": "12",
      "md": "",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 pa-20px pa-md-30px pa-lg-20px pa-xl-30px"
  }, [_c('div', {
    staticClass: "overflow-hidden"
  }, [_c('tit-wrap-subtitle', {
    staticClass: "tit-wrap--sm",
    attrs: {
      "titSize": "xs"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("브랜드관")];
      },
      proxy: true
    }, {
      key: "subTit",
      fn: function () {
        return [_vm._v("핫한 브랜드만 모았습니다!")];
      },
      proxy: true
    }])
  }), _c('v-responsive', {
    attrs: {
      "aspect-ratio": 720 / 316
    }
  }, [_c('v-card', {
    staticClass: "main-brand-slide"
  }, [_c('div', {
    staticClass: "txt txt--sm d-flex justify-center align-center"
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-arrow-left-right")]), _vm._v(" 슬라이드 해서 브랜드들을 확인해 보세요 ")], 1), _c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    var _item$label;
    return [_c('swiper-slide', {
      key: item._id
    }, [_c('v-card', {
      attrs: {
        "to": `/shop/brands/${item === null || item === void 0 ? void 0 : item.code}`
      }
    }, [_c('div', {
      staticClass: "main-brand-slide__img",
      style: `background-image:url(${item === null || item === void 0 ? void 0 : (_item$label = item.label) === null || _item$label === void 0 ? void 0 : _item$label.url});`
    })])], 1)];
  })], 2)], 1)], 1)], 1)])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }