<template>
    <v-app>
        <!-- S: BANNER -->
        <slot name="bandBanner" />
        <!-- E: BANNER -->

        <!-- S: HEADER -->
        <main-header v-bind="{ disableScrollTop }" />
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <v-container class="container--lg">
                    <div data-v-sticky-container class="fixed-contents">
                        <v-row justify="center" class="row--contents flex-nowrap">
                            <v-col cols="12" xl="auto" class="flex-shrink-1" :class="contentsColClass">
                                <!-- S: contents -->
                                <div :class="contentsClass">
                                    <slot />
                                </div>
                                <!-- E: contents -->
                            </v-col>
                            <v-col cols="auto" class="align-self-stretch d-none d-xl-block">
                                <v-divider vertical class="h-100 mr-n1px" />
                            </v-col>
                            <v-col cols="auto" class="d-none d-xl-block py-0">
                                <!-- S: 사이드 영역 -->
                                <div v-sticky="stickyOptions" class="w-290px">
                                    <main-aside v-if="!hasCustomAside" />
                                    <slot name="custom-aside" />
                                </div>
                                <!-- E: 사이드 영역 -->
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer />
        <!-- E: FOOTER -->

        <!-- S: SNS Button -->
        <sns-button />
        <!-- E: SNS Button -->
    </v-app>
</template>

<script>
import { mapActions } from "vuex";

import VueStickyDirective from "@renatodeleao/vue-sticky-directive";

import MainAside from "@/components/client/inc/main-aside/main-aside.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import MainHeader from "@/components/client/inc/main-header.vue";

import SnsButton from "@/components/client/inc/sns-button.vue"

import "@mdi/font/css/materialdesignicons.css"
export default {
    components: {
        MainAside,
        MainFooter,
        MainHeader,

        SnsButton,
    },
    props: {
        hasCustomAside: { type: Boolean, default: false },
        disableScrollTop: { type: Boolean, default: false },
        contentsColClass: { type: String, default: "flex-grow-1" },
        contentsClass: { type: String, default: "mw-1330px" },
    },
    directives: {
        VueStickyDirective,
    },
    data: () => ({
        loading: false,

        stickyOptions: {
            resizeSensor: true,
            topSpacing: 12,
            bottomSpacing: 12,
        },
    }),
    mounted() {
        this.init();
        this.enlipleTracker();
        this.naverWcs();
    },
    watch: {
        "$route.path"() {
            this.enlipleTracker();
            this.naverWcs();
        },
    },
    methods: {
        ...mapActions("enlipleTracker", ["callMtm"]),
        ...mapActions("naverWcs", ["wcs_do"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.$nextTick(() => {
                    setTimeout(() => {}, 500);
                });
            } finally {
                this.loading = false;
            }
        },
        async enlipleTracker() {
            const toCall = !["/shop/products/", "/shop/result"].some((path) => this.$route.path.includes(path));
            if (toCall) this.callMtm();
        },
        async naverWcs() {
            if (!this.$route.path.includes("/shop/result")) this.wcs_do();
        },
    },
};
</script>

<style lang="scss" scoped></style>
