<template>
    <page-section class="page-section--sm pb-0" v-if="accessToken">
        <tit-wrap-small title="내 정보">
            <v-btn x-small text color="grey" class="v-btn--text-underline" to="/mypage/myinfo">내 정보 관리</v-btn>
        </tit-wrap-small>

        <v-row class="row--sm">
            <v-col cols="12">
                <v-row align="center" class="row--xs">
                    <v-col cols="auto">
                        <v-card rounded="circle" color="grey lighten-4" class="w-40px">
                            <v-img :aspect-ratio="1 / 1">
                                <div class="d-flex align-center justify-center h-100">
                                    <i class="icon icon-heart" />
                                </div>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="auto">
                        <div class="txt txt--xs txt--dark">
                            <span class="d-inline-flex align-center">
                                <b class="font-weight-bold"> {{ user?.username }} </b> <span class="pl-2px">님</span>
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card outlined color="grey darken-4" to="/mypage/points" class="overflow-hidden">
                    <div class="px-16px py-14px">
                        <v-row align="center" class="row--xs txt txt--xs txt--dark font-weight-medium">
                            <v-col cols="auto">
                                <span>보유 포인트</span>
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto" class="text-right">
                                <span class="d-inline-flex align-center">
                                    <b class="txt--sm"> {{ (user?.point || 0)?.format?.() }} </b> <span class="pl-2px">원</span>
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
            <!-- <v-col cols="12">
                <v-card outlined color="grey darken-4" class="overflow-hidden">
                    <div class="px-16px py-14px">
                        <v-row align="center" class="row--xs txt txt--xs txt--dark font-weight-medium">
                            <v-col cols="auto">
                                <span>보유 예치금</span>
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto" class="text-right">
                                개발 미작업
                                <span class="d-inline-flex align-center"> <b class="txt--sm">254,000</b><span class="pl-2px">원</span> </span>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col> -->
            <v-col cols="12">
                <main-aside-me-coupon />
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import PageSection from "@/components/client//templates/page-section.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import MainAsideMeCoupon from "@/components/client/inc/main-aside/main-aside-me-coupon.vue";

export default {
    components: {
        PageSection,
        TitWrapSmall,
        MainAsideMeCoupon,
    },
    computed: {
        ...mapState(["accessToken", "user"]),
    },
    mounted() {
        if (this.accessToken) this.getUser();
    },
    watch: {
        accessToken() {
            if (this.accessToken) this.getUser();
        },
    },
    methods: {
        ...mapActions(["getUser"]),
    },
};
</script>

<style>
</style>