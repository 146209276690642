var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__inner"
  }, [_c('div', {
    staticClass: "header-head grey lighten-5"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "divider-group divider-group--lg"
  }, [_c('ul', [_c('li', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold",
    attrs: {
      "x-small": "",
      "color": "primary",
      "href": "http://joeyforlife.pagecheck.co.kr/",
      "target": "_blank"
    }
  }, [_vm._v("Joeyforlife 1.0")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit font-weight-regular",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey darken-4",
      "to": "/VR-showroom"
    }
  }, [_vm._v("VR Showroom")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit font-weight-regular",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey darken-4",
      "to": "/3d-interir"
    }
  }, [_vm._v("3D Interior")])], 1)], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "href": "https://www.youtube.com/@joeyforlife/featured",
      "target": "_blank",
      "text": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-youtube"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "href": "https://www.instagram.com/joeyforlifemall",
      "target": "_blank",
      "text": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-instagram"
  })])], 1)], 1)], 1)])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit font-weight-medium",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey darken-4",
      "to": "/shop/showrooms"
    }
  }, [_vm._v("온라인 쇼룸")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit font-weight-medium",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey darken-4",
      "to": "/center/notice"
    }
  }, [_vm._v("고객센터")])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "header-body"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('div', {
    staticClass: "header-contents"
  }, [_c('div', {
    staticClass: "w-100 mw-1330px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h1', {
    staticClass: "logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.logo
    }
  })], 1)], 1)]), _c('v-col', [_c('div', {
    staticClass: "header-search"
  }, [_c('div', {
    staticClass: "header-search__form d-flex align-center"
  }, [_c('v-text-field', {
    staticClass: "ma-0 pc-search-input",
    attrs: {
      "placeholder": "제품 또는 브랜드를 검색해 주세요.",
      "solo": "",
      "hide-details": ""
    },
    on: {
      "focus": function ($event) {
        _vm.searchFocused = true;
      },
      "blur": _vm.onSearchBlur,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([!_vm.searchFocused ? {
      key: "prepend-inner",
      fn: function () {
        return [_c('button', {
          staticClass: "ml-2 mr-3"
        }, [_c('icon-search')], 1)];
      },
      proxy: true
    } : null, _vm.searchFocused ? {
      key: "append",
      fn: function () {
        return [_c('button', {
          staticClass: "ml-3 mr-2",
          on: {
            "click": _vm.search
          }
        }, [_c('icon-search')], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1)])])], 1)], 1), _c('div', {
    staticClass: "header-user-menu"
  }, [_c('ul', [_vm.accessToken ? _c('li', [_c('div', {
    staticClass: "link cursor-pointer",
    on: {
      "click": _vm.logout
    }
  }, [_c('icon-logout')], 1)]) : _vm._e(), _vm.accessToken ? _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/dashboard"
    }
  }, [_c('icon-person')], 1)], 1) : _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/join"
    }
  }, [_c('icon-person'), _c('span', {
    staticClass: "header-user-menu__txt d-none d-xl-block"
  }, [_vm._v("회원가입 시 즉시 5% 할인!")])], 1)], 1), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/likes"
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined"
  }, [_vm._v("favorite_border")])])], 1), _c('li', [_c('main-cart-button', {
    attrs: {
      "to": "/mypage/cart"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var carts = _ref.carts;
        return [_c('span', [_c('v-badge', {
          attrs: {
            "value": carts.length != 0,
            "dot": "",
            "bordered": "",
            "overlap": "",
            "color": "primary"
          }
        }, [_c('icon-shopping-bag')], 1)], 1)];
      }
    }])
  })], 1)])])])])], 1), _c('div', {
    staticClass: "header-foot"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "header-category"
  }, [_c('button', {
    staticClass: "header-category__button",
    attrs: {
      "type": "button",
      "id": "category"
    }
  }, [_c('span', {
    staticClass: "header-category__hamburger"
  }, [_c('span', {
    staticClass: "header-category__hamburger-line"
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("전체 카테고리")])])])]), _c('div', {
    staticClass: "header-category__full"
  }, [_c('main-shop-categories', {
    attrs: {
      "pc": ""
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "header-menu"
  }, [_c('main-shop-categories', {
    attrs: {
      "menu": ""
    }
  })], 1)])], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "header-menu d-block"
  }, [_c('ul', {
    staticClass: "header-menu__lists"
  }, [_c('li', {
    staticClass: "header-menu__list"
  }, [_c('router-link', {
    staticClass: "header-menu__list-link",
    attrs: {
      "to": {
        path: '/shop',
        query: {
          groups: _vm.DISPLAY_CODES.BEST_SELLER.value
        }
      }
    }
  }, [_vm._v("베스트")])], 1), _vm._l(_vm.groups, function (_ref2) {
    var text = _ref2.text,
      value = _ref2.value;
    return _c('li', {
      key: value,
      staticClass: "header-menu__list"
    }, [_c('router-link', {
      staticClass: "header-menu__list-link",
      attrs: {
        "to": {
          path: '/shop/exhibitions',
          query: {
            group: value
          }
        }
      }
    }, [_vm._v(_vm._s(text))])], 1);
  })], 2)])])], 1)], 1)], 1)]), _c('div', {
    staticClass: "mobile-header"
  }, [_c('h1', {
    staticClass: "logo"
  }, [_c('router-link', {
    staticStyle: {
      "max-width": "46vw"
    },
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.logo
    }
  })], 1)], 1), _c('button', {
    staticClass: "mobile-header__search"
  }, [_c('icon-search'), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("상품검색창열기")])], 1), _c('main-shop-categories-mobile')], 1), _c('div', {
    staticClass: "mobile-searchbar"
  }, [_c('div', {
    staticClass: "mobile-searchbar__head"
  }, [_vm._m(0), _c('div', {
    staticClass: "mobile-searchbar__form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "mobile-searchbar__input",
    attrs: {
      "type": "text",
      "name": "searchValue",
      "placeholder": "검색어를 입력하세요."
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "mobile-searchbar__button",
    attrs: {
      "name": "button"
    },
    on: {
      "click": _vm.search
    }
  }, [_c('icon-search'), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("검색")])], 1)])])]), _vm._m(1), _c('div', {
    staticClass: "mobile-sidebar"
  }, [_c('div', {
    staticClass: "mobile-sidebar-head"
  }, [_vm._m(2), _c('div', {
    staticClass: "mobile-sidebar__info"
  }, [!_vm.accessToken ? _c('router-link', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인 해주세요")]) : _c('a', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])], 1)]), _c('div', {
    staticClass: "mobile-sidebar-body"
  }, [_c('ul', {
    staticClass: "mobile-sidebar__mylink"
  }, [_vm.accessToken ? _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/myinfo"
    }
  }, [_c('icon-person'), _c('span', [_vm._v("마이페이지")])], 1)], 1) : _vm._e(), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/cart"
    }
  }, [_c('icon-shopping-bag'), _vm._v(" 장바구니 ")], 1)], 1)]), _c('ul', {
    staticClass: "mobile-sidebar__link"
  }, [_c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        path: '/shop',
        query: {
          groups: _vm.DISPLAY_CODES.BEST_SELLER.value
        }
      }
    }
  }, [_vm._v("베스트"), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1), _vm._l(_vm.groups, function (_ref3) {
    var text = _ref3.text,
      value = _ref3.value;
    return _c('li', {
      key: value
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": {
          path: '/shop/exhibitions',
          query: {
            group: value
          }
        }
      }
    }, [_vm._v(_vm._s(text)), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1);
  })], 2), _c('main-shop-categories', {
    attrs: {
      "mobile": ""
    }
  })], 1)]), _c('div', {
    staticClass: "mobile-header-dimmed"
  })]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "mobile-searchbar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("모바일 검색창 닫기")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-hamburger"
  }, [_c('button', {
    staticClass: "mobile-hamburger__btn",
    attrs: {
      "type": "button",
      "aria-pressed": "false"
    }
  }, [_c('span', {
    staticClass: "mobile-hamburger__line"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "mobile-sidebar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("모바일 메뉴바 닫기")])]);

}]

export { render, staticRenderFns }