var render = function render(){
  var _vm$product, _vm$product$thumb, _vm$product2, _vm$product2$flags, _vm$product3, _vm$review, _vm$review$user, _vm$review2, _vm$review3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('router-link', {
    attrs: {
      "to": `/shop/products/${_vm.product._id}`
    }
  }, [_c('v-card', {
    staticClass: "mx-auto"
  }, [_c('div', {
    staticClass: "square-img",
    style: `background-image: url(${(_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$thumb = _vm$product.thumb) === null || _vm$product$thumb === void 0 ? void 0 : _vm$product$thumb.url})`
  }, [_c('div', {
    staticClass: "square-img__badge"
  }, [_vm._v(_vm._s(_vm.index))])])]), _c('div', {
    staticClass: "pt-10px pt-md-20px"
  }, [(_vm$product2 = _vm.product) !== null && _vm$product2 !== void 0 && (_vm$product2$flags = _vm$product2.flags) !== null && _vm$product2$flags !== void 0 && _vm$product2$flags.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold text-truncate"
  }, [_vm._v(_vm._s(_vm.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('div', {
    staticClass: "txt txt--sm txt--dark text-truncate mt-4px"
  }, [_vm._v(_vm._s((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name))]), _c('div', {
    staticClass: "mt-10px mt-md-20px"
  }, [_c('v-card', {
    staticClass: "border-dark",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px"
  }, [_c('div', {
    staticClass: "pb-8px pb-md-12px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "w-40px",
    attrs: {
      "rounded": "circle",
      "color": "grey lighten-4"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center h-100"
  }, [_c('i', {
    staticClass: "icon icon-heart"
  })])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark"
  }, [_c('span', {
    staticClass: "d-inline-flex align-center"
  }, [_c('b', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s((_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : (_vm$review$user = _vm$review.user) === null || _vm$review$user === void 0 ? void 0 : _vm$review$user.username))]), _c('span', {
    staticClass: "pl-2px"
  }, [_vm._v("님")])])])])], 1)], 1), _c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": (_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : _vm$review2.star
    }
  }), _c('div', {
    staticClass: "pt-8px"
  }, [_c('p', {
    staticClass: "txt txt--xs text-truncate-2"
  }, [_vm._v(_vm._s((_vm$review3 = _vm.review) === null || _vm$review3 === void 0 ? void 0 : _vm$review3.content))])])], 1)])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }