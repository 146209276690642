var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-row', {
    staticClass: "mx-n10px my-n10px mx-lg-n20px"
  }, [_c('v-col', {
    staticClass: "px-10px py-10px px-lg-20px",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('main-products-best-banner', {
    attrs: {
      "code": _vm.BANNERS.MAIN_4.code__pc,
      "isPc": ""
    }
  }), _c('main-products-best-banner', {
    attrs: {
      "code": _vm.BANNERS.MAIN_4.code__mb,
      "isMb": ""
    }
  })], 1), _c('v-col', {
    staticClass: "px-10px py-10px px-lg-20px",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('main-products-best-displays', {
    attrs: {
      "code": _vm.DISPLAY_CODES.BEST_SELLER.value
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }