<template>
    <v-card outlined color="grey darken-4" to="/mypage/coupons" class="overflow-hidden">
        <div class="px-16px py-14px">
            <v-row align="center" class="row--xs txt txt--xs txt--dark font-weight-medium">
                <v-col cols="auto">
                    <span>보유 쿠폰</span>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="text-right">
                    <v-icon size="20" color="grey darken-4">mdi-chevron-right</v-icon>
                </v-col>
                <v-col v-if="coupons.length" cols="12">
                    <!-- 개발 미작업 -->
                    <template v-for="coupon in coupons">
                        <div class="primary--text font-weight-regular" :key="coupon._id">
                            <v-row class="row--xxs">
                                <v-col cols="auto">·</v-col>
                                <v-col>{{ coupon.name }}</v-col>
                            </v-row>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
export default {
    data: () => ({
        coupons: [],
    }),
    computed: {
        ...mapState({ _user: (state) => state?.payload?._user }),
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.path"() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            this.coupons = [];

            try {
                if (this._user) {
                    const { coupons } = await api.v1.me.coupons.gets({
                        headers: { limit: 5 },
                        params: { isUsed: false, isExpired: false },
                    });
                    this.coupons = coupons;
                }
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>