<template>
    <div class="main-visual-tabs" style="border-radius: 8px; overflow: hidden">
        <v-tabs-items v-model="tab" touchless>
            <!-- S: 메인비주얼 -->
            <v-tab-item transition="slide-y-transition" reverse-transition="slide-y-transition">
                <main-visual-tab-visual :code="BANNERS.MAIN_1.code__pc" isPc />
                <main-visual-tab-visual :code="BANNERS.MAIN_1.code__mb" isMb />
            </v-tab-item>
            <!-- E: 메인비주얼 -->
            <!-- S: 케어제품 -->
            <v-tab-item transition="slide-y-transition" reverse-transition="slide-y-transition">
                <main-visual-tab-care @close="tab = 0" />
            </v-tab-item>
            <!-- E: 케어제품 -->
            <!-- S: 시그니처 -->
            <v-tab-item transition="slide-y-transition" reverse-transition="slide-y-transition">
                <main-visual-tab-signature @close="tab = 0" />
            </v-tab-item>
            <!-- E: 시그니처 -->
            <!-- S: 브랜드관 -->
            <v-tab-item transition="slide-y-transition" reverse-transition="slide-y-transition">
                <main-visual-tab-brand @close="tab = 0" />
            </v-tab-item>
            <!-- E: 브랜드관 -->
            <!-- S: 커뮤니티 -->
            <v-tab-item transition="slide-y-transition" reverse-transition="slide-y-transition">
                <main-visual-tab-community @close="tab = 0" />
            </v-tab-item>
            <!-- E: 커뮤니티 -->
        </v-tabs-items>

        <v-tabs v-model="tab" grow hide-slider class="v-tabs--block">
            <v-tab class="d-none">메인비주얼</v-tab>
            <v-tab>케어제품</v-tab>
            <v-tab>시그니처 쇼룸</v-tab>
            <v-tab>브랜드관</v-tab>
            <v-tab>커뮤니티</v-tab>
        </v-tabs>
    </div>
</template>

<script>
import { attrs_input, BANNERS } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";

import MainVisualTabCare from "./main-visual-tab-care.vue";
import MainVisualTabBrand from "./main-visual-tab-brand.vue";
import MainVisualTabVisual from "./main-visual-tab-visual.vue";
import MainVisualTabSignature from "./main-visual-tab-signature.vue";
import MainVisualTabCommunity from "./main-visual-tab-community.vue";

export default {
    components: {
        PageSection,

        MainVisualTabCare,
        MainVisualTabBrand,
        MainVisualTabVisual,
        MainVisualTabSignature,
        MainVisualTabCommunity,
    },
    data: () => ({
        tab: null,

        BANNERS,
        attrs_input,
    }),
    methods: {
        init() {
            this.tab = 0;
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
    .main-visual-tabs {
        display: flex;
        flex-direction: column;
        height: 100%;
        ::v-deep {
            .v-tabs-bar,
            .v-tab {
                height: 100%;
            }
        }
        .v-tabs {
            height: 100%;
            max-height: 56px;
        }
        .v-tab {
            min-height: 38px;
            font-size: 16px;
        }
    }
}
</style>
