<template>
    <page-section>
        <v-row class="mx-n10px my-n10px mx-lg-n20px">
            <v-col cols="12" lg="6" class="px-10px py-10px px-lg-20px">
                <main-products-best-banner :code="BANNERS.MAIN_4.code__pc" isPc />
                <main-products-best-banner :code="BANNERS.MAIN_4.code__mb" isMb />
            </v-col>
            <v-col cols="12" lg="6" class="px-10px py-10px px-lg-20px">
                <main-products-best-displays :code="DISPLAY_CODES.BEST_SELLER.value" />
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import { BANNERS, DISPLAY_CODES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import MainProductsBestBanner from "./main-products-best-banner.vue";
import MainProductsBestDisplays from "./main-products-best-displays.vue";

export default {
    components: {
        PageSection,
        MainProductsBestBanner,
        MainProductsBestDisplays,
    },
    data: () => ({
        BANNERS,
        DISPLAY_CODES,
    }),
};
</script>
