<template>
    <page-section class="page-section--sm pb-0" v-if="accessToken">
        <tit-wrap-small title="주문내역">
            <v-btn x-small text color="grey" class="v-btn--text-underline" to="/mypage/orders">더 보기</v-btn>
        </tit-wrap-small>

        <v-row class="row--sm">
            <template v-for="item in items">
                <v-col cols="12" :key="item._id">
                    <order-view v-model="item.order">
                        <template #activator="{ attrs, on }">
                            <v-card v-bind="attrs" v-on="on">
                                <v-row align="center" class="flex-nowrap">
                                    <v-col cols="auto">
                                        <v-card width="94">
                                            <div class="square-img" :style="`background-image: url(${item?.product?.thumb?.url})`" />
                                        </v-card>
                                    </v-col>
                                    <v-col cols="" style="max-width: calc(100% - 114px); flex: 0 0 calc(100% - 114px)">
                                        <div class="txt txt--sm txt--dark font-weight-bold text-truncate">[5%할인특가] [회원가입]</div>
                                        <div class="txt txt--sm txt--dark text-truncate mt-4px">{{ item?.product?.name }}</div>
                                        <div class="txt txt--xs txt--light text-truncate mt-12px">{{ $getStatusText(item) }}</div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                    </order-view>
                </v-col>
            </template>
            <template v-if="!items.length">
                <v-col class="text-center"> 주문내역이 없습니다 </v-col>
            </template>
        </v-row>
    </page-section>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

import OrderView from "@/components/client/mypage/order/order-view.vue";
import PageSection from "@/components/client//templates/page-section.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";

export default {
    components: {
        OrderView,
        PageSection,
        TitWrapSmall,
    },
    data: () => ({
        purchases: [],
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            return this.purchases;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.path"() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.loading = false;

                this.purchases = [];
                this.summary = { totalCount: 0 };

                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            if (!this.accessToken) return;
            if (this.loading) return;
            this.loading = true;

            try {
                let { summary, purchases } = await api.v1.me.purchases.gets({
                    headers: { skip: 0, limit: 3 },
                });

                this.summary = summary;
                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>