var render = function render(){
  var _vm$user, _ref, _ref$format, _vm$user2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.accessToken ? _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "내 정보"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-underline",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey",
      "to": "/mypage/myinfo"
    }
  }, [_vm._v("내 정보 관리")])], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "w-40px",
    attrs: {
      "rounded": "circle",
      "color": "grey lighten-4"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center h-100"
  }, [_c('i', {
    staticClass: "icon icon-heart"
  })])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark"
  }, [_c('span', {
    staticClass: "d-inline-flex align-center"
  }, [_c('b', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.username) + " ")]), _vm._v(" "), _c('span', {
    staticClass: "pl-2px"
  }, [_vm._v("님")])])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "outlined": "",
      "color": "grey darken-4",
      "to": "/mypage/points"
    }
  }, [_c('div', {
    staticClass: "px-16px py-14px"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs txt--dark font-weight-medium",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("보유 포인트")])]), _c('v-spacer'), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "d-inline-flex align-center"
  }, [_c('b', {
    staticClass: "txt--sm"
  }, [_vm._v(" " + _vm._s((_ref = ((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.point) || 0) === null || _ref === void 0 ? void 0 : (_ref$format = _ref.format) === null || _ref$format === void 0 ? void 0 : _ref$format.call(_ref)) + " ")]), _vm._v(" "), _c('span', {
    staticClass: "pl-2px"
  }, [_vm._v("원")])])])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('main-aside-me-coupon')], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }