var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual-tabs",
    staticStyle: {
      "border-radius": "8px",
      "overflow": "hidden"
    }
  }, [_c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "transition": "slide-y-transition",
      "reverse-transition": "slide-y-transition"
    }
  }, [_c('main-visual-tab-visual', {
    attrs: {
      "code": _vm.BANNERS.MAIN_1.code__pc,
      "isPc": ""
    }
  }), _c('main-visual-tab-visual', {
    attrs: {
      "code": _vm.BANNERS.MAIN_1.code__mb,
      "isMb": ""
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "transition": "slide-y-transition",
      "reverse-transition": "slide-y-transition"
    }
  }, [_c('main-visual-tab-care', {
    on: {
      "close": function ($event) {
        _vm.tab = 0;
      }
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "transition": "slide-y-transition",
      "reverse-transition": "slide-y-transition"
    }
  }, [_c('main-visual-tab-signature', {
    on: {
      "close": function ($event) {
        _vm.tab = 0;
      }
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "transition": "slide-y-transition",
      "reverse-transition": "slide-y-transition"
    }
  }, [_c('main-visual-tab-brand', {
    on: {
      "close": function ($event) {
        _vm.tab = 0;
      }
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "transition": "slide-y-transition",
      "reverse-transition": "slide-y-transition"
    }
  }, [_c('main-visual-tab-community', {
    on: {
      "close": function ($event) {
        _vm.tab = 0;
      }
    }
  })], 1)], 1), _c('v-tabs', {
    staticClass: "v-tabs--block",
    attrs: {
      "grow": "",
      "hide-slider": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    staticClass: "d-none"
  }, [_vm._v("메인비주얼")]), _c('v-tab', [_vm._v("케어제품")]), _c('v-tab', [_vm._v("시그니처 쇼룸")]), _c('v-tab', [_vm._v("브랜드관")]), _c('v-tab', [_vm._v("커뮤니티")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }